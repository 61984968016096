import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { clinicListAction } from "redux/Actions/clinicListAction";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export const getClinicListSlice = createSlice({
  name: "getClinicList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(clinicListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(clinicListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(clinicListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getClinicListSlice.reducer;