import React from "react";
import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { APIs } from "Services/APIs";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";

import MDButton from "components/MDButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import "./price.css"; // Import the CSS file
import axios from "../../../../axiosinstance";

import MDBox from "components/MDBox";
import { ROLE_CODE } from "privateComponents/codes";
import { useSelector } from "react-redux";

const PriceInfo = (props) => {
  const { pricePopup, setPricePopup, data, getApiResponse,priceList  } = props;
  const [currencyptions, setCurrencyOptions] = useState([]);
  const [itemoptions, setItemOptions] = useState([]);
  const [typeoptions, setTypeOptions] = useState([]);
  const [uomoptions, setUomOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const {getMMUDetails, getProjectDetails} = useSelector(state => state)
  const [username] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.name
  );

  const [categoryoptions, setCategoryOptions] = useState([]);
  const [pricetypevalue, setPriceTypevalue] = useState("FLAT");
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    type: Yup.string().required("Type is required."),
    pricetype: Yup.string().required("Price type is required."),
    uom: Yup.string().required("UOM is required."),
    unit: Yup.string().required("Unit is required."),
    category: Yup.string().required("Category is required."),
    currency: Yup.string().when("pricetype", {
      is: "Flat",
      then: Yup.string().required("This field is required."),
      //   .matches(/^\d+(\.\d+)?%?$/, 'Please enter a valid percentage'),
      // otherwise: Yup.string(),
    }),
    value: Yup.string().required("Value text is required."),
    tax: Yup.string()
      .oneOf(["Tax Include", "Tax Exclude"], "Please select a tax.")
      .required("Tax preference is required."),
  });

  const handleClose = () => {
    setCategoryOptions([]);
    setPricePopup(false); // Close the dialog
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.itemmasterServiceApi}/getV3?type=M_PRICE_ITEM`
      );
      setItemOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // type list come from operation
    try {
      const response = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=M_PRICE_OPERATION&categorytype=M_LAB_OPTIONS`
      );
      setTypeOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // Dropdown(comes from uom Master) for currency
    try {
      const response = await axios.get(
        `${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS&category=Currency`
      );
      setCurrencyOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // Dropdown(comes from uom Master) for uom
    try {
      const response = await axios.get(
        `${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS&category=Time`
      );
      setUomOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };

  async function getCategory(id) {
    let url = ""
    if(ROLE_CODE.labcode == auth.rolecode){
      url = `usercategory-service/api/Usercategory/v1/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&Category.type=CAT`
    }else{
      url = `usercategory-service/api/Usercategory/v1/get?profileid=${id}&Category.type=CAT`
    }
    await axios
      .get(url)
      .then((response) => {
        const result = response.data.data.dtoList.filter(
          (productItem) => productItem.profileid === id
        );

        // Set the product options based on the result
        // setProductlist(result);
        setCategoryOptions(result);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    setCategoryOptions([]);
    getCategory(profileId);

    setPriceTypevalue(data?.currency);
  }, [data]);

  useEffect(() => {
    getData();
    getCategory(profileId);
  }, []);

  return (
    <Dialog open={pricePopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "white",
          textAlign: "center",
        }}
      >
        {data ? "Edit Price" : "Create Price"}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            category: data ? data.code : "",
            description: data ? data?.description : "",
            type: data ? data.type : "",
            pricetype: data ? data?.valuetype : "",
            name: data ? data?.name : "",
            currency: data ? data?.currency : "",
            uom: data ? data.uom : "",
            tax: data ? data.taxinclusiveflag : "",
            value: data ? data?.value : "",
            unit: data ? data?.unit : "",
            categoryName: data ? data?.pricename : "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, action) => {
            setLoading(true);
            try {
              const checkduplicate = priceList.find(
                (option) => option.type === values.type && option.pricename === values.categoryName
              );
              const selectedOption = typeoptions.find(
                (option) => option.name === values.type
              );

              const descriptionOperation = selectedOption
                ? selectedOption.description
                : "";

              const itemOption = itemoptions.find(
                (option) => option.name === values.name
              );

              const sapcode = itemOption ? itemOption.sapcode : "";

              if (checkduplicate) {
               
                swal(
               
                  "Opps!",
                  "This price has already been created.",
                  "warning"
                );
                setLoading(false);
              }else{
              if (data != null) {
                const updateData = {
                  ...data,
                  code: values.category,
                  createdby: username,
                  currency: values.currency,
                  description: values.description,
                  itemcode: sapcode,
                  modifiedby: username,
                  modifieddate: null,
                  name: values.name,
                  pricename: values.categoryName
                    ? values.categoryName
                    : data?.pricename,
                  operationtype: descriptionOperation,
                  profileid: profileId,
                  taxinclusiveflag: values.tax,
                  type: values.type,
                  unit: values.unit,
                  uom: values.uom,
                  value: values.value,
                  valuetype: values.pricetype,
                };

                await axios.put(`${APIs.priceServiceApi}/update`, updateData);
                swal(
                  "Good job!",
                  "Price has been updated successfully.",
                  "success"
                );
              } else {
                const newData = {
                  code: values.category,
                  createdby: username,
                  currency: values.currency,
                  description: values.description,
                  itemcode: sapcode,
                  modifiedby: username,
                  modifieddate: null,
                  name: values.name,
                  mmucode:auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode ? getProjectDetails?.details?.mmucreation?.code : "",
                  pricename: values.categoryName,
                  operationtype: descriptionOperation,
                  profileid: profileId,
                  recstatus: "OPEN",
                  status: "ACTIVE",
                  syncstatus: "SYNCED",
                  taxinclusiveflag: values.tax,
                  type: values.type,
                  unit: values.unit,
                  uom: values.uom,
                  value: values.value,
                  valuetype: values.pricetype,
                };

                await axios.post(`${APIs.priceServiceApi}/create`, newData);

                swal(
                  "Good job!",
                  "Price has been created successfully.",
                  "success"
                );
              }
            
              action.resetForm();
              setPricePopup(false);
              getApiResponse();
              getData();
              setLoading(false);
            }
            } catch (error) {
              if (error.response?.data == null) {
                swal("Oops!", "Something went wrong!", "error");
              } else {
                swal(
                  "Oops!",
                  error.response?.data?.aceErrors[0]?.errorMessage,
                  "warning"
                );
              }
              setLoading(false);
            }
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="name"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.name)}
                      >
                        Select Name{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={(e) => {
                          const selectedName = e.target.value;

                          setFieldValue("name", selectedName);
                        }}
                        error={touched.name && Boolean(errors.name)}
                      >
                        <option value=""></option>
                        {itemoptions.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="category"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.category)}
                      >
                        Select Test{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="category"
                        name="category"
                        value={values.category}
                        onChange={(e) => {
                          const selectedCategoryCode = e.target.value;
                          const selectedCategory = categoryoptions.find(
                            (option) =>
                              option.category?.code === selectedCategoryCode
                          );

                          setFieldValue("category", selectedCategoryCode);
                          setFieldValue(
                            "categoryName",
                            selectedCategory?.category?.name || ""
                          );
                        }}
                        error={touched.category && Boolean(errors.category)}
                      >
                        <option value=""></option>
                        {categoryoptions.map((option) => (
                          <option key={option.id} value={option.category?.code}>
                            {option?.category?.name}
                          </option>
                        ))}
                      </Select>

                      <ErrorMessage
                        name="category"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>Description</span>
                        name="description"
                        id="description"
                        value={values.description}
                        onChange={(e) => {
                          const nameDescription = e.target.value;
                          setFieldValue("description", nameDescription);
                        }}
                        variant="standard"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="type"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.type)}
                      >
                        Type{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="type"
                        name="type"
                        value={values.type}
                        onChange={(e) => {
                          const selectedType = e.target.value;

                          setFieldValue("type", selectedType);
                        }}
                        error={touched.type && Boolean(errors.type)}
                      >
                        <option value=""></option>
                        {typeoptions.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="type"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="pricetype"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                      >
                        Price Type{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        name="pricetype"
                        id="pricetype"
                        value={values.pricetype}
                        onChange={(e) => {
                          const selectedType = e.target.value;

                          setPriceTypevalue(selectedType);
                          if (selectedType === "Percentage") {
                            setFieldValue("currency", "");
                          }
                          setFieldValue("pricetype", selectedType);
                        }}
                        error={touched.pricetype && Boolean(errors.pricetype)}
                      >
                        <option value=""></option>
                        <option value="Flat">Flat</option>
                        <option value="Percentage">Percentage</option>
                      </Select>
                      <ErrorMessage
                        name="pricetype"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  {pricetypevalue === "Percentage" ||
                  pricetypevalue === "" ? null : (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="currency"
                          style={{
                            marginLeft: "-14px",
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                          shrink={Boolean(values.currency)}
                        >
                          Currency{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          fullWidth
                          variant="standard"
                          style={{ width: "100%" }}
                          id="currency"
                          name="currency"
                          value={values.currency}
                          onChange={(e) => {
                            const selectedCurrency = e.target.value;

                            setFieldValue("currency", selectedCurrency);
                          }}
                          error={touched.currency && Boolean(errors.currency)}
                        >
                          <option value=""></option>
                          {currencyptions.map((option) => (
                            <option key={option.id} value={option.displaytext}>
                              {option.displaytext}
                            </option>
                          ))}
                        </Select>
                        <ErrorMessage
                          name="currency"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Value
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="value"
                        type="number"
                        id="value"
                        value={values.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("value", value);
                        }}
                        variant="standard"
                        error={touched.value && Boolean(errors.value)}
                        helperText={touched.value && errors.value}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="uom"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.uom)}
                      >
                        UOM{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="uom"
                        name="uom"
                        value={values.uom}
                        onChange={(e) => {
                          const selectedUom = e.target.value;

                          setFieldValue("uom", selectedUom);
                        }}
                        error={touched.uom && Boolean(errors.uom)}
                      >
                        <option value=""></option>
                        {uomoptions.map((option) => (
                          <option key={option.id} value={option.displaytext}>
                            {option.displaytext}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="uom"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Unit
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="unit"
                        type="number"
                        id="unit"
                        value={values.unit}
                        onChange={(e) => {
                          const value = e.target.value;
                          setFieldValue("unit", value);
                        }}
                        variant="standard"
                        error={touched.unit && Boolean(errors.unit)}
                        helperText={touched.unit && errors.unit}
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid className="radio-container" mt={2}>
                  <span>
                    Tax Preference
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  <label>
                    <Field
                      type="radio"
                      name="tax"
                      value="Tax Include"
                      style={{ width: "5%" }}
                    />
                    <span className="radio-label" style={{ fontSize: "15px" }}>
                      Tax Include
                    </span>
                  </label>
                  <label>
                    <Field
                      type="radio"
                      name="tax"
                      value="Tax Exclude"
                      style={{ width: "5%" }}
                    />
                    <span className="radio-label" style={{ fontSize: "15px" }}>
                      Tax Exclude
                    </span>
                  </label>
                  <ErrorMessage
                    name="tax"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </Grid>

                {/* <Grid item xs={12} sm={9}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      onClick={handleClose}
                    >
                      Cancel
                    </MDButton>
                  </Grid> */}
                <MDBox
                  display="flex"
                  justifyContent="flex-end"
                  marginRight="10px"
                  marginBottom="8px"
                >
                  <MDButton type="submit" variant="gradient" color="info">
                    {loading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : data ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default PriceInfo;
