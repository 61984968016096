import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete, CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import FormField from "../FormField/index";
import axios from "../../../../../axiosinstance";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BarLoader } from "react-spinners";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ErrorMessage, useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import { getProject } from "components/Resuable/Create Project/service/Projectcreate";
import { APIs } from "Services/APIs";
import { clinicappCode, labappCode } from "static/labappCode";
import { ROLE_CODE } from "privateComponents/codes";
import MapPopup from "app/agent/registrationResource/components/Map/MapPopup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MDButton from "components/MDButton";
import SelectComponent from "components/SelectComponent";

function ClinicprojectcreateInfo({ formData }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [color] = useState("#344767");
  const [openpopup, setopenpopup] = useState(false);
  const [statelist, setStateList] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldError, validateField } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const [parenthierachicalValue, setparenthierachicalValue] = useState(null);
  const [parenthierachicalList, setparenthierachicalList] = useState("");
  const [maplocation, setMaplocation] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [subdistrictValue, setSubDistrictValue] = useState(null);
  const [subDistrictLoading, setSubDistrictLoading] = useState(false);
  const [pincodeValue, setPincodeValue] = useState(null);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const { getProjectTeam, getProjectDetails } = useSelector((state) => state);

  console.log(getProjectDetails, "getProjectDetails 64")

  const {
    name,
    description,
    firstline,
    secondline,
    country,
    latitude,
    longitude,
    state,
    subDistrict,
    pincode,
  } = formField;

  var {
    name: nameV,
    description: descriptionV,
    firstline: firstlineV,
    secondline: secondlineV,
    country: countryV,
    latitude: latitudeV,
    longitude: longitudeV,
    state: stateV,
    subDistrict: subDistrictV,
    pincode: pincodeV,
  } = values;

  const mapdata = {
    firstline: values.firstline || "",
    secondline: values.secondline || "",
    city:
      values.subdistrictname && values.districtname
        ? `${values.subdistrictname} ${values.districtname}`
        : "",
    country: values.country || "",
    state: values.statename || "",
  };

  const defaultPropsparenthierachical = {
    options: !parenthierachicalList
      ? [{ name: "Loading...", id: 0 }]
      : parenthierachicalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function getStateData() {
    setLoading(true);
    const response = await dispatch(getState());
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setStateList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No State data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setLoading(false);
  }

  async function getDistrictData(statecode) {
    setDistrictLoading(true);
    const response = await dispatch(getDistrict(statecode));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setDistrict(response?.payload?.data?.dtoList);
      } else {
        swal("", "No District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setDistrictLoading(false);
  }

  async function getSubDistrictData(statecode, districtCode) {
    setSubDistrictLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };
    const response = await dispatch(getSubDistrict(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setSubDistrict(response.payload.data.dtoList);
      } else {
        swal("", "No Sub District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setSubDistrictLoading(false);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    setPincodeLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setPincodeList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No Pincode data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setPincodeLoading(false);
  }

  async function getData() {
    let url =
      auth?.rolecode === ROLE_CODE?.admincode
        ? `${APIs?.projectServiceApi}/get?type=${clinicappCode?.mappingcode}`
        : `${APIs?.projectServiceApi}/getByPageV2?projectcode=${getProjectDetails?.details?.projecthirarichalcode}&type=${clinicappCode?.mappingcode}`;
    const response = await axios.get(url);
    if (!response.error) {
      setProjectList(response?.data?.data?.dtoList);
      setparenthierachicalList(response?.data?.data?.dtoList);
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
  }
  const defaultPropsState = {
    options:
      statelist && statelist.length > 0
        ? statelist
        : [
            {
              state: {
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) => option.state?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.state?.name === "Loading...",
  };

  const defaultPropsDistrict = {
    options:
      district && district.length > 0
        ? district
        : [
            {
              district: {
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) => option.district?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.district?.name === "Loading...",
  };

  const defaultPropsSubDistrict = {
    options:
      subdistrict && subdistrict.length > 0
        ? subdistrict
        : [
            {
              subdistrict: {
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) => option.subdistrict?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.subdistrict?.name === "Loading...",
  };

  const defaultPropsPincode = {
    options:
      pincodelist && pincodelist.length > 0
        ? pincodelist
        : [
            {
              village: {
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) => option.village?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.village?.name === "Loading...",
  };
  const handleStateChange = (event, newValue) => {
    setStateValue(newValue);
    const selectedStateobj =
      statelist?.find((obj) => obj?.state?.code == newValue?.state?.code) || {};
    setFieldValue("statename", selectedStateobj?.state?.name);
    setFieldValue("state", newValue?.state?.code || "");
    validateField("state");
    if (selectedStateobj?.state?.code) {
      getDistrictData(selectedStateobj?.state?.code);
    } else {
      setDistrict([]);
      setSubDistrict([]);
      setPincodeList([]);
      values.district = "";
      setDistrictValue(null);
      setSubDistrictValue(null);
      setPincodeValue(null);
      values.subDistrict = "";
      values.pincode = "";
      setFieldValue("district", "");
      setFieldValue("subDistrict", "");
      setFieldValue("pincode", "");
      setFieldValue("state", "");
    }
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrictValue(newValue);

    const selectedDistrictobj =
      district?.find(
        (obj) => obj?.district?.code === newValue?.district?.code
      ) || {};
    setFieldValue("districtname", selectedDistrictobj?.district?.name);
    setFieldValue("district", newValue?.district?.code || "");
    validateField("district");
    if (
      selectedDistrictobj?.state?.code &&
      selectedDistrictobj?.district?.code
    ) {
      getSubDistrictData(
        selectedDistrictobj?.state?.code,
        selectedDistrictobj?.district?.code
      );
    } else {
      setSubDistrict([]);
      setPincodeList([]);
      setSubDistrictValue(null);
      setPincodeValue(null);
      values.subDistrict = "";
      values.pincode = "";
      setFieldValue("subDistrict", "");
      setFieldValue("pincode", "");
      setFieldValue("district", "");
    }
  };

  const handleSubDistrictChange = (event, newValue) => {
    setSubDistrictValue(newValue);
    const selectedPincodeobj =
      subdistrict?.find(
        (obj) => obj?.subdistrict?.code === newValue?.subdistrict?.code
      ) || {};
    setFieldValue("subdistrictname", selectedPincodeobj?.subdistrict?.name);
    setFieldValue("subDistrict", newValue?.subdistrict?.code || "");
    validateField("subDistrict");
    if (
      selectedPincodeobj?.state?.code &&
      selectedPincodeobj?.district?.code &&
      selectedPincodeobj?.subdistrict?.code
    ) {
      getVillageData(
        selectedPincodeobj?.state?.code,
        selectedPincodeobj?.district?.code,
        selectedPincodeobj?.subdistrict?.code
      );
    } else {
      setPincodeList([]);
      setPincodeValue(null);
      values.pincode = "";
      setFieldValue("pincode", "");
      setFieldValue("subDistrict", "");
    }
  };

  const handlePincodeChange = (event, newValue) => {
    setPincodeValue(newValue);
    setFieldValue("pincodeobj", newValue);
    setFieldValue("pincode", newValue?.village?.code || "");
    validateField("pincode");
  };
  useEffect(() => {
    getData();
    getStateData();
  }, [dispatch]);

  const handleDataTransfer = (data) => {
    if (data) {
      setMaplocation(data);
      values.latitude = data.lat || "";
      values.longitude = data.lng || "";
    } else {
      setMaplocation(null);
      values.latitude = "";
      values.longitude = "";
    }
  };

  const handleParentHierarchical = (e) => {
    const projecthirarichalcode = e.target.value;
    setFieldValue("projecthirarichalcode", projecthirarichalcode.code);
  };

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
              label=<span>
                {name.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label=<span>{description.label}</span>
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
           
            <FormControl
              fullWidth
              error={
                !!errors.projecthirarichalcode && touched.projecthirarichalcode
              }
            >
              <InputLabel
                htmlFor="projecthirarichalcode"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Parent Hierarchical
              </InputLabel>

              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="projecthirarichalcode"
                id="projecthirarichalcode"
                value={values.projecthirarichalcode}
                onChange={(e) => {
                  const parentproject = e.target.value;
                  const projecthirarichalcode = projectList?.find(
                    (i) => i.code === parentproject
                  )?.projecthirarichalcode;
                  setFieldValue("projecthierarchy", projecthirarichalcode);
                  setFieldValue("projecthirarichalcode", parentproject);
                }}
              >
                <option value=""></option>
                {projectList?.map((obj) => (
                  <option key={obj?.id} value={obj?.code}>
                    {obj?.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Address Information
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstline.type}
              name={firstline.name}
              value={firstlineV}
              placeholder={firstline.placeholder}
              error={errors.firstline && touched.firstline}
              success={firstlineV.length > 0 && !errors.firstline}
              label=<span>
                {firstline.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={secondline.type}
              name={secondline.name}
              value={secondlineV}
              placeholder={secondline.placeholder}
              label=<span>{secondline.label}</span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropsState}
              value={stateValue}
              onChange={handleStateChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={
                      stateValue != null ||
                      ("" && !errors.state && touched.state)
                    }
                    variant="standard"
                    label=<span>
                      {state.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="state"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropsDistrict}
              value={districtValue}
              onChange={handleDistrictChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.district && touched.district}
                    success={
                      districtValue != null ||
                      ("" && !errors.district && touched.district)
                    }
                    variant="standard"
                    label=<span>
                      District
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {districtLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="district"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <Autocomplete
              {...defaultPropsSubDistrict}
              value={subdistrictValue}
              onChange={handleSubDistrictChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.subDistrict && touched.subDistrict}
                    success={
                      subdistrictValue != null ||
                      ("" && !errors.subDistrict && touched.subDistrict)
                    }
                    variant="standard"
                    label=<span>
                      {subDistrict.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {subDistrictLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="subDistrict"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <Autocomplete
              {...defaultPropsPincode}
              value={pincodeValue}
              onChange={handlePincodeChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.pincode && touched.pincode}
                    success={
                      pincodeValue != null ||
                      ("" && !errors.pincode && touched.pincode)
                    }
                    variant="standard"
                    label=<span>
                      {pincode.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {pincodeLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <FormField
              type={country.type}
              name={country.name}
              value={countryV}
              placeholder={country.placeholder}
              error={errors.country && touched.country}
              success={countryV.length > 0 && !errors.country}
              InputLabelProps={{
                shrink: true,
                sx: { fontSize: "0.75rem", marginTop: countryV.length > 0 ? 0 : 2.5 }, 
              }}
              label=<span>
                {country.label}
                
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            <MDButton
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                const shouldOpenPopup =
                  mapdata.firstline &&
                  mapdata.city &&
                  mapdata.country &&
                  mapdata.state &&
                  values.pincode;
                if (shouldOpenPopup) {
                  setopenpopup(true);
                } else {
                  setopenpopup(false);
                  swal({
                    icon: "warning",
                    title: "Incomplete Data",
                    text: "Please ensure all address fields are filled.",
                  });
                }
              }}
              sx={{
                width: "150px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RemoveRedEyeIcon />
              &nbsp; Add Location
            </MDButton>
            {maplocation && (
              <Grid item xs={12} sm={1} mt={1.5}>
                <CheckCircleIcon fontSize="medium" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>

      {openpopup ? (
        <MapPopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Select Location"}
          onDataTransfer={handleDataTransfer}
          data2={mapdata}
        />
      ) : (
        <></>
      )}
    </MDBox>
  );
}

ClinicprojectcreateInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ClinicprojectcreateInfo;
