import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/Shared/DashboardNavbar";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMaterialUIController } from "context";
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";
import Sidenav from "components/Sidenav";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import HomeSidenavSkeleton from "components/Skeleton/HomeSidenavSkeleton";
import { projectDetailsAction } from "redux/Actions/projectDetailsAction";
import swal from "sweetalert";
import { clinicRoutes } from "routes/clinicRoutes";
import ClinicPofile from "./profile/ClinicPofile";
import ClinicBookingList from "./ClinicBookingList";
import ClinicPrice from "./clinicPrice/ClinicPrice";
import SlotList from "./slot/SlotList";


const ClinicLayout = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectDetails } = useSelector(state => state);
  const [controller] = useMaterialUIController();
  const { slug } = useParams();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const handleDataFromAddress = (data) => {
    setAddress(data);
  };
  const handleDataFromCategory = (data) => {
    setCategory(data);
  };

  useEffect(()=>{
    dispatch(projectDetailsAction({ profileId: auth.profileid }))
  },[auth])

  useEffect(()=>{
    if(getProjectDetails?.error !== null && getProjectDetails?.error?.response?.data?.aceErrors[0]?.errorMessage){
      console.log(getProjectDetails?.error?.response, "error data")
      let message = "You have not assigned with any project."
      swal(message, {
        icon: 'error',
        buttons: {
          confirm: {
            text: 'OK',
            value: true,
          },
        },
      }).then((value) => {
        if (value) {
          dispatch(projectDetailsAction({ clear: true }))
          dispatch({ type: "LOGOUT_FULFILLED" })
        }
      });
    }
    
  },[getProjectDetails.error, slug])  

  


  return (
    <>
      <Suspense fallback={<HomeSidenavSkeleton />}>
      <DashboardLayout>
        <div style={{ position: "fixed", width: "100%", zIndex: "1000" }}>
          <DashboardNavbar />
        </div>
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Medharva"
          routes={clinicRoutes}
        />

        {slug === "profile" && <ClinicPofile />}
        {slug === "booking-list" && <ClinicBookingList />}
        <div style={{ marginTop: "50px" }}>
        {slug === "price-list" && <ClinicPrice />}
      
        </div>
        <div style={{ marginTop: "70px" }}>
      
        {slug === "slot-list" && <SlotList data={auth?.profileid} disabled={true} />}
        </div>
        
     
      </DashboardLayout>
     
      </Suspense>
    </>
  );
};

export default ClinicLayout; 
