import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import ProfileInformation from "components/Shared/profileInformation/ProfileInformation";
import React, { useEffect, useState } from "react";

import Slotbooking from "components/Shared/Slot";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { MMUDetailsAction } from "redux/Actions/mmuDetailsAction";
import { getProjectTeamAction } from "redux/Actions/getProjectTeamAction";
import { micDetailsAction } from "redux/Actions/micDetailsAction";
import ClinicPrice from "../clinicPrice/ClinicPrice";
import SlotList from "../slot/SlotList";
import CategoryList from "components/Shared/categoryList/CategoryList";
import VisitCategory from "components/Shared/vistcategory/VisitCategory";

const ClinicPofile = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const [visit, setVisit] = useState([]);

  useEffect(() => {
    // dispatch(MMUDetailsAction({ createdby: auth.createdby }));
    dispatch(getProjectTeamAction({ emailId: auth.emailid }));
    // dispatch(micDetailsAction({ profileId: auth?.profileid }));
  }, [auth]);

  const handleDataFromAddress = (data) => {
    setAddress(data);
  };
  const handleDataFromCategory = (data) => {
    setCategory(data);
  };
  const handleDataFromVisit = (data) => {
    setVisit(data);
  };

  return (
    <>
      <ProfileInformation />

      {/* <MDBox px={2.5}>
        <CategoryList sendDataToCategory={handleDataFromCategory} />
      </MDBox> */}
      {/* <MDBox px={2.5}>
      
        <VisitCategory sendDataToVisit={handleDataFromVisit} />
      </MDBox> */}
      {/* <MDBox px={2.5} mt={2.5}>
      
        <ClinicPrice />
      </MDBox> */}
      {/* <MDBox px={2.5} mt={2.5}>
      
        <SlotList data={auth?.profileid} disabled={true} />
      </MDBox> */}
    </>
  );
};

export default ClinicPofile;
