import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Dialog, FormControlLabel, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../../axiosinstance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";

function Createslot(props) {
  const { popup, setPopup, getSlotData, dateFormat, profileid } = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [addresslist, setAddresslist] = useState([]);

  const [idCounter, setIdCounter] = useState(0);
  const currentDate = new Date();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();
  const { getMMUDetails, getProjectDetails } = useSelector((state) => state);
  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };
  const [addressValue, setAddressValue] = useState(null);

  const initialValues = {
    addressobject: "",
    slotsize: "",
    averageusercount: "",
    enddate: currentDate.toISOString().split("T")[0],
    startdate: currentDate.toISOString().split("T")[0],

    startTime: getCurrentTime(),
    endTime: getCurrentTime(),
  };

  // const validationSchema = Yup.object({
  //   addressobject: Yup.string().required("Address is required"),
  //   slotsize: Yup.string().required("Slot is required"),
  //   averageusercount: Yup.string().required("Count is required"),
  //   enddate: Yup.string().required("End Date is required"),
  //   startdate: Yup.string().required("Start Date is required"),
  //   startTime: Yup.string().required("Start Time is required"),
  //   endTime: Yup.string()
  //     .required("End Time is required")
  //     .test(
  //       "is-greater",
  //       "End Time must be greater than Start Time",
  //       function (value) {
  //         const { startTime } = this.parent;
  //         if (!startTime || !value) {
  //           return true;
  //         }
  //         return startTime < value;
  //       }
  //     ),
  // });

  const validationSchema = Yup.object({
    addressobject:
      auth?.rolecode === ROLE_CODE.labcode ||
      auth?.rolecode === ROLE_CODE.childlabcode
        ? Yup.string()
        : Yup.string().required("Address is required."),
    slotsize: Yup.string().required("Slot size is required."),
    averageusercount: Yup.string().required("Count is required."),
    enddate: Yup.string()
      .required("End date is required.")
      .test(
        "is-valid-end-date",
        "End date must be greater than or equal to start date.",
        function (value) {
          const { startdate } = this.parent;
          if (!startdate || !value) {
            return true; // Let other validations handle missing dates
          }
          return new Date(value) >= new Date(startdate);
        }
      ),
    startdate: Yup.string()
      .required("Start date is required.")
      .test(
        "is-future-start-date",
        "Start date must be current or future.",
        function (value) {
          const currentDate = new Date();
          return new Date(value) >= currentDate.setHours(0, 0, 0, 0); // Set current date to midnight for comparison
        }
      ),
    startTime: Yup.string().required("Start time is required."),
    endTime: Yup.string()
      .required("End time is required.")
      .test(
        "is-greater",
        "End time must be greater than start time.",
        function (value) {
          const { startTime } = this.parent;
          if (!startTime || !value) {
            return true;
          }
          return startTime < value;
        }
      ),
  });

  function addDateWithTime(date, time) {
    // let time = '05:17 pm';
    let splittedTime = time.split(" ");
    let [hours, minutes] = splittedTime[0].split(":");
    let isAm = splittedTime[1] === "am"; // in europe we don't use am, the hours goes from 0 to 24

    // var date = new Date(); // now
    // console.log('before ' + date);

    let hourToAdd = 0;
    if (+hours === 12) {
      if (isAm) {
        hourToAdd = -12; // at midnight we dont add +12 nor +0, we want 12am to be 0 hours
      } else {
        hourToAdd = 0;
      }
    } else {
      hourToAdd = isAm ? 0 : 12;
    }

    // use setHours to set time
    date.setHours(+hours + hourToAdd, +minutes, 0, 0);
    // console.log('after ' + date);
    return date;
  }
  function tConvert(time) {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " am" : " pm"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  }
  function dateFormater(date) {
    return (
      new Date(date).toLocaleDateString() +
      " " +
      tConvert(new Date(date).toLocaleTimeString())
    );
  }

  const handleSubmit = (values, { resetForm }) => {
    var updateStartTime = tConvert(values.startTime);

    var startDate = addDateWithTime(
      new Date(values.startdate),
      updateStartTime
    );

    var updateEndTime = tConvert(values.endTime);
    var endDate = addDateWithTime(new Date(values.enddate), updateEndTime);

    const newData = {
      endTime: values.endTime,
      startTime: values.startTime,
      uid: idCounter,
      enddate: endDate,
      startdate: startDate,
      averageusercount: values.averageusercount,
      slotsize: values.slotsize,
      addressobject:
        auth?.rolecode === ROLE_CODE?.labcode ||
        auth?.rolecode === ROLE_CODE?.childlabcode
          ? addressValue
          : JSON.parse(values.addressobject),
      id: null,
      fstartedate: dateFormater(startDate),
      fenddate: dateFormater(endDate),
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "synced",

      profileid:
        auth.rolecode === ROLE_CODE.admincode
          ? location?.state?.profileid
          : auth?.profileid,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    resetForm();
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "start date", accessor: "fstartedate" },
    { Header: "end date", accessor: "fenddate" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];
  async function finasubmit() {
    setLoading(true);

    if (data.length === 0) {
      swal("Oops", "Please add data in the table first!", "error");
      setLoading(false);
    } else {
      var finalObjectlist = [];

      for (let i = 0; i < data.length; i++) {
        var startDate = new Date(data[i].startdate);
        var endDate = new Date(data[i].enddate);

        while (startDate <= endDate) {
          var timeStart = new Date(startDate);
          timeStart.setHours(data[i].startTime.split(":")[0]);
          timeStart.setMinutes(data[i].startTime.split(":")[1]);

          var timeEnd = new Date(startDate);
          timeEnd.setHours(data[i].endTime.split(":")[0]);
          timeEnd.setMinutes(data[i].endTime.split(":")[1]);

          // Adjust for the time zone offset
          const timeStartUTC = new Date(
            timeStart.getTime() - timeStart.getTimezoneOffset() * 60000
          );
          const timeEndUTC = new Date(
            timeEnd.getTime() - timeEnd.getTimezoneOffset() * 60000
          );

          const diff = (timeEndUTC - timeStartUTC) / 60000;

          const time = data[i].slotsize.split(" ");
          const slotNo = diff / (time[0] * 1);

          for (let j = 0; j < slotNo; j++) {
            var slotStart = new Date(timeStartUTC);
            slotStart.setMinutes(slotStart.getMinutes() + j * time[0] * 1);

            var slotEnd = new Date(slotStart);
            slotEnd.setMinutes(slotEnd.getMinutes() + time[0] * 1);

            const object = {
              addressobject: data[i]?.addressobject,
              averageusercount: data[i]?.averageusercount,
              createdby: auth?.name,
              enddate: slotEnd.toISOString().replace(/\.\d+/, ""),
              mmucode:auth?.rolecode === ROLE_CODE?.labcode ||
                      auth?.rolecode === ROLE_CODE?.childlabcode
                        ? getProjectDetails?.details?.mmucreation?.code
                        : "",
              // mmucode:
              //   auth?.rolecode === ROLE_CODE?.labcode ||
              //   auth?.rolecode === ROLE_CODE?.childlabcode
              //     ? getMMUDetails?.data?.mmucode
              //     : "",
              id: null,
              profileid:
                auth.rolecode === ROLE_CODE.admincode
                  ? location?.state?.profileid
                  : auth?.profileid,
              recstatus: "OPEN",
              slotsize: data[i]?.slotsize,
              startdate: slotStart.toISOString().replace(/\.\d+/, ""),
              status: "ACTIVE",
              syncstatus: "synced",
            };

            finalObjectlist.push(object);
          }

          startDate.setDate(startDate.getDate() + 1); // Move to the next day
        }
      }

   
      await axios
        .post("event-service/api/event/v1/createList", finalObjectlist)

        .then((response) => {
          console.log("time", response);

          swal("Good job!", "Slot has been Added!", "success");
          handleClose();

          getSlotData(profileid, dateFormat(new Date()));
          // navigate(`/app/${auth.rolename?.toLowerCase()}/profile`);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setLoading(false);
        });
    }
  }
  const handleClose = () => {
    setPopup(false);
  };
  async function getData(profileId) {
    await axios
      .get(`${APIs.addressServiceApi}/get?profileId=${profileId}`)
      .then((response) => {
        setAddresslist(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getMMUData(mmucode) {
    await axios
      .get(`${APIs.mmuCreationService}/get?code=${mmucode}`)
      .then((response) => {
        setAddressValue(response?.data?.data?.dtoList?.[0]?.address);

        setLoading(false);
      })
  
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    if (
      auth?.rolecode === ROLE_CODE?.labcode ||
      auth?.rolecode === ROLE_CODE?.childlabcode
    ) {
      getMMUData(getProjectDetails?.details?.mmucreation?.code);
    } else {
      getData(
        location?.state?.profileid
          ? location?.state?.profileid
          : auth?.profileid
      );
    }
  }, []);

  return (
    <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
      <>
        <MDBox py={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
              mt: 3,
            }}
          >
            <Grid item xs={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          Slot Information
                        </MDTypography>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="white"
                          my={1}
                        >
                          Enter your detail
                        </MDTypography>
                      </MDBox>
                      {auth?.rolecode === ROLE_CODE.admincode ? (
                        <MDTypography variant="h5">
                          Selected : {location?.state?.name}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        {auth?.rolecode === ROLE_CODE?.labcode ||
                        auth?.rolecode === ROLE_CODE?.childlabcode ? (
                          <></>
                        ) : (
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <InputLabel
                                htmlFor="addressobject"
                                style={{
                                  marginLeft: "-14px",
                                  paddingBottom: "2px",
                                  marginTop: "2px",
                                }}
                              >
                                Address Selection{" "}
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </InputLabel>
                              <Select
                                native
                                variant="standard"
                                style={{ width: "100%" }}
                                name="addressobject"
                                id="addressobject"
                                value={values.addressobject}
                                onChange={(e) => {
                                  const selectedMode = e.target.value;
                                  setFieldValue("addressobject", selectedMode);
                                }}
                                error={
                                  touched.addressobject &&
                                  Boolean(errors.addressobject)
                                }
                              >
                                <option value=""></option>

                                {addresslist?.map((obj) => (
                                  <option
                                    key={obj.id}
                                    value={JSON.stringify(obj)}
                                  >
                                    {obj?.firstline +
                                      " " +
                                      obj?.secondline +
                                      " " +
                                      obj?.district +
                                      " " +
                                      obj?.state}
                                  </option>
                                ))}
                              </Select>
                              <ErrorMessage
                                name="addressobject"
                                component="div"
                                style={{ color: "red", fontSize: "12px" }}
                              />
                            </FormControl>
                          </Grid>
                        )}

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="slotsize"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Slot Size{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="slotsize"
                              id="slotsize"
                              value={values.slotsize}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue("slotsize", selectedType);
                              }}
                              error={
                                touched.slotsize && Boolean(errors.slotsize)
                              }
                            >
                              <option value=""></option>

                              <option value="15 min">15 min</option>
                              <option value="20 min">20 min</option>
                              <option value="30 min">30 min</option>
                              <option value="45 min">45 min</option>
                              <option value="60 min">60 min</option>
                            </Select>
                            <ErrorMessage
                              name="slotsize"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Average Count Per Slot
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="number"
                              name="averageusercount"
                              variant="standard"
                              value={values.averageusercount}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("averageusercount", nameValue);
                              }}
                            />

                            <ErrorMessage
                              name="averageusercount"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Start Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="startdate"
                              variant="standard"
                              value={values.startdate}
                              onChange={(e) => {
                                const startdateValue = e.target.value;
                                setFieldValue("startdate", startdateValue);
                              }}
                              error={
                                touched.startdate && Boolean(errors.startdate)
                              }
                            />
                            <ErrorMessage
                              name="startdate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                End Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="enddate"
                              variant="standard"
                              value={values.enddate}
                              onChange={(e) => {
                                const enddateValue = e.target.value;
                                setFieldValue("enddate", enddateValue);
                              }}
                              error={touched.enddate && Boolean(errors.enddate)}
                            />
                            <ErrorMessage
                              name="enddate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label={
                                <span>
                                  Start Time{" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                      fontWeight: "bolder",
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </sup>
                                </span>
                              }
                              type="time"
                              name="startTime"
                              variant="standard"
                              value={values.startTime}
                              onChange={(e) => {
                                const starttimeValue = e.target.value;
                                setFieldValue("startTime", starttimeValue);
                              }}
                              error={
                                touched.startTime && Boolean(errors.startTime)
                              }
                            />
                            <ErrorMessage
                              name="startTime"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label={
                                <span>
                                  End Time{" "}
                                  <sup
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                      fontWeight: "bolder",
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </sup>
                                </span>
                              }
                              type="time"
                              name="endTime"
                              variant="standard"
                              value={values.endTime}
                              onChange={(e) => {
                                const endtimeValue = e.target.value;
                                setFieldValue("endTime", endtimeValue);
                              }}
                              error={touched.endTime && Boolean(errors.endTime)}
                            />
                            <ErrorMessage
                              name="endTime"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={12}
                          display="flex"
                          justifyContent="flex-end"
                        >
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            size="small"
                          >
                            Add
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                        imageMaxWidth="110px"
                      />
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      marginRight="10px"
                      marginBottom="8px"
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => finasubmit()}
                      >
                        {loading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "submit"
                        )}
                      </MDButton>
                    </MDBox>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>
      </>
    </Dialog>
  );
}

export default Createslot;
