import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{brandName, brandDescrption},
} = checkout;

const validations = [
  Yup.object().shape({
    [brandName.name]: Yup.string().required(brandName.errorMsg),
    [brandDescrption.name]: Yup.string().required(brandDescrption.errorMsg),
  }),
];

export default validations
