import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormField from "../FormField";
import axios from "../../../axiosinstance";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BarLoader } from "react-spinners";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  IconButton,
  FormHelperText,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ErrorMessage, useFormikContext } from "formik";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import MDButton from "components/MDButton";
import { FaRegTimesCircle } from "react-icons/fa";
import MapPopup from "app/agent/registrationResource/components/Map/MapPopup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DataTable from "examples/Tables/DataTable";
import { APIs } from "Services/APIs";

function LabCreateInfo({ formData }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [color] = useState("#344767");
  const [openpopup, setopenpopup] = useState(false);
  const [statelist, setStateList] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pincodelist, setPincodeList] = useState([]);
  const { setFieldValue, setFieldError, validateField } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const [data, setData] = useState([]);
  const [maplocation, setMaplocation] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [subdistrictValue, setSubDistrictValue] = useState(null);
  const [subDistrictLoading, setSubDistrictLoading] = useState(false);
  const [pincodeValue, setPincodeValue] = useState(null);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [mapwarevalue, setmapwarevalue] = React.useState(null);
  const [raspberryvalue, setraspberryvalue] = React.useState(null);
  const [VehicleAssignList, setVehicleAssignList] = useState([]);
  const [mapwareList, setmapwareList] = useState([]);
  const [raspberryList, setRasberryList] = useState([]);
  const [VehicleAssignvalue, setVehicleAssignvalue] = React.useState(null);
  const [AssignEquipmentList, setAssignEquipmentList] = useState([]);
  const [AssignEquipmentvalue, setAssignEquipmentvalue] = React.useState(null);
  const {
    name,
    description,
    firstline,
    secondline,
    VehicleAssign,
    mapware,
    RaspberryAssign,
    country,
    latitude,
    longitude,
    state,
    subDistrict,
    pincode,
  } = formField;

  var {
    name: nameV,
    description: descriptionV,
    firstline: firstlineV,
    secondline: secondlineV,
    country: countryV,
    latitude: latitudeV,
    longitude: longitudeV,
    state: stateV,
    subDistrict: subDistrictV,
    pincode: pincodeV,
  } = values;

  const mapdata = {
    firstline: values.firstline || "",
    secondline: values.secondline || "",
    city: `${values.subdistrictname || ""} ${values.districtname || ""}`,
    country: values.country || "",
    state: values.statename || "",
  };

  async function getData() {
    setLoading(true);
    const response = await dispatch(getState());
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setStateList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No State data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setLoading(false);
  }

  async function getDistrictData(statecode) {
    setDistrictLoading(true);
    const response = await dispatch(getDistrict(statecode));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setDistrict(response?.payload?.data?.dtoList);
      } else {
        swal("", "No District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setDistrictLoading(false);
  }

  async function getSubDistrictData(statecode, districtCode) {
    setSubDistrictLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };
    const response = await dispatch(getSubDistrict(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setSubDistrict(response?.payload?.data?.dtoList);
      } else {
        swal("", "No Sub District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setSubDistrictLoading(false);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    setPincodeLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setPincodeList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No Pincode data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setPincodeLoading(false);
  }
  async function getList() {
    //resberry=================
    await axios
      .get(`${APIs.itemmasterServiceApi}/get?type=RASPBERRY-PI`)
      .then((response) => {
        setRasberryList(response?.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
    // VEHICLE=========
    await axios
      .get(`${APIs.itemmasterServiceApi}/get?type=VEHICLE`)
      .then((response) => {
        setVehicleAssignList(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
    //Assignequmnet=============
    await axios
      .get(`${APIs.itemmasterServiceApi}/get?type=EQUIPMENT`)
      .then((response) => {
        setAssignEquipmentList(response?.data?.data?.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });

    //warehouse
    await axios
      .get(`${APIs.warehouseServiceApi}/get`)
      .then((response) => {
        setmapwareList(response?.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
    getList();
  }, [dispatch]);

  const handleDataTransfer = (data) => {
    if (data) {
      setMaplocation(data);
      values.latitude = data.lat || "";
      values.longitude = data.lng || "";
    } else {
      setMaplocation(null);
      values.latitude = "";
      values.longitude = "";
    }
  };

  const defaultPropsState = {
    options:
      statelist && statelist.length > 0
        ? statelist
        : [
          {
            state: {
              name: "Loading...",
            },
            id: 0,
          },
        ],
    getOptionLabel: (option) => option.state?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.state?.name === "Loading...",
  };

  const defaultPropsDistrict = {
    options:
      district && district.length > 0
        ? district
        : [
          {
            district: {
              name: "Loading...",
            },
            id: 0,
          },
        ],
    getOptionLabel: (option) => option.district?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.district?.name === "Loading...",
  };

  const defaultPropsSubDistrict = {
    options:
      subdistrict && subdistrict.length > 0
        ? subdistrict
        : [
          {
            subdistrict: {
              name: "Loading...",
            },
            id: 0,
          },
        ],
    getOptionLabel: (option) => option.subdistrict?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.subdistrict?.name === "Loading...",
  };

  const defaultPropsPincode = {
    options:
      pincodelist && pincodelist.length > 0
        ? pincodelist
        : [
          {
            village: {
              name: "Loading...",
            },
            id: 0,
          },
        ],
    getOptionLabel: (option) => option.village?.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
    getOptionDisabled: (option) => option.village?.name === "Loading...",
  };

  const handleStateChange = (event, newValue) => {
    setStateValue(newValue);
    const selectedStateobj =
      statelist?.find((obj) => obj?.state?.code == newValue?.state?.code) || {};
    setFieldValue("statename", selectedStateobj?.state?.name);
    setFieldValue("state", newValue?.state?.code || "");
    validateField("state");
    if (selectedStateobj?.state?.code) {
      getDistrictData(selectedStateobj?.state?.code);
    } else {
      setDistrict([]);
      setSubDistrict([]);
      setPincodeList([]);
      values.district = "";
      setDistrictValue(null);
      setSubDistrictValue(null);
      setPincodeValue(null);
      values.subDistrict = "";
      values.pincode = "";
      setFieldValue("district", "");
      setFieldValue("subDistrict", "");
      setFieldValue("pincode", "");
      setFieldValue("state", "");
    }
  };

  const handleDistrictChange = (event, newValue) => {
    setDistrictValue(newValue);

    const selectedDistrictobj =
      district?.find(
        (obj) => obj?.district?.code === newValue?.district?.code
      ) || {};
    setFieldValue("districtname", selectedDistrictobj?.district?.name);
    setFieldValue("district", newValue?.district?.code || "");
    validateField("district");
    if (
      selectedDistrictobj?.state?.code &&
      selectedDistrictobj?.district?.code
    ) {
      getSubDistrictData(
        selectedDistrictobj?.state?.code,
        selectedDistrictobj?.district?.code
      );
    } else {
      setSubDistrict([]);
      setPincodeList([]);
      setSubDistrictValue(null);
      setPincodeValue(null);
      values.subDistrict = "";
      values.pincode = "";
      setFieldValue("subDistrict", "");
      setFieldValue("pincode", "");
      setFieldValue("district", "");
    }
  };
  // Map Ware-house
  const defaultPropssetMapWarehouse = {
    options: !mapwareList ? [{ name: "Loading...", id: 0 }] : mapwareList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // Raspberry Assign==========
  const defaultPropssetRaspberryAssign = {
    options: !raspberryList ? [{ name: "Loading...", id: 0 }] : raspberryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  // vechicle assigned
  const defaultPropssetVehicleAssign = {
    options: !VehicleAssignList
      ? [{ name: "Loading...", id: 0 }]
      : VehicleAssignList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleSubDistrictChange = (event, newValue) => {
    setSubDistrictValue(newValue);
    const selectedPincodeobj =
      subdistrict?.find(
        (obj) => obj?.subdistrict?.code === newValue?.subdistrict?.code
      ) || {};
    setFieldValue("subdistrictname", selectedPincodeobj?.subdistrict?.name);
    setFieldValue("subDistrict", newValue?.subdistrict?.code || "");
    validateField("subDistrict");
    if (
      selectedPincodeobj?.state?.code &&
      selectedPincodeobj?.district?.code &&
      selectedPincodeobj?.subdistrict?.code
    ) {
      getVillageData(
        selectedPincodeobj?.state?.code,
        selectedPincodeobj?.district?.code,
        selectedPincodeobj?.subdistrict?.code
      );
    } else {
      setPincodeList([]);
      setPincodeValue(null);
      values.pincode = "";
      setFieldValue("pincode", "");
      setFieldValue("subDistrict", "");
    }
  };

  const handlePincodeChange = (event, newValue) => {
    setPincodeValue(newValue);
    setFieldValue("pincodeobj", newValue);
    setFieldValue("pincode", newValue?.village?.code || "");
    validateField("pincode");
  };
  const [assignequiment, setAssignequiment] = useState("");
  const [quantity, setQuantity] = useState("");
  const [assetno, setAssetno] = useState("");
  const [list, setList] = useState([]);

  const handleAdd = () => {
    if (assignequiment && quantity && assetno) {
      // Update AssignEquipmentvalue with the new values
      // AssignEquipmentvalue.inputQty = quantity;
      // AssignEquipmentvalue.inputSerialNo = assetno;


      const newEntry = {
        item: AssignEquipmentvalue,
        quantity: quantity,
        assetno: assetno

      };


      setList([...list, newEntry]);

      setAssignequiment("");
      setAssignEquipmentvalue(null)
      setQuantity("");
      setAssetno("");

      // Update the form values with the new list
      values.equimentslist = [...list, newEntry];
    } else {
      swal({
        icon: "warning",
        title: "Incomplete Data",
        text: "Please fill out all fields before adding.",
      });
    }
  };

  const handleDelete = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };
  useEffect(() => {
    values.equimentslist = list
  }, [list])
  
  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
              label=<span>
                {name.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label=<span>{description.label}</span>
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropssetRaspberryAssign}
              value={raspberryvalue}
              onChange={(event, newValue) => {
                // handleChange(event);

                setraspberryvalue(newValue);

                values.RaspberryAssign = newValue;
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  // error={errors.mapware && touched.mapware}
                  // success={mapware.length > 0 && !errors.mapware}
                  variant="standard"
                  label={RaspberryAssign.label}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropssetMapWarehouse}
              value={mapwarevalue}
              onChange={(event, newValue) => {
                // handleChange(event);

                setmapwarevalue(newValue);

                values.mapware = newValue;
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  // error={errors.mapware && touched.mapware}
                  // success={mapware.length > 0 && !errors.mapware}
                  variant="standard"
                  label={mapware.label}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropssetVehicleAssign}
              value={VehicleAssignvalue}
              onChange={(event, newValue) => {
                // handleChange(event);

                setVehicleAssignvalue(newValue);

                values.VehicleAssign = newValue;
                // console.log(newValue);
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  // error={errors.VehicleAssign && touched.VehicleAssign}
                  // success={VehicleAssign.length > 0 && !errors.VehicleAssign}
                  variant="standard"
                  label={VehicleAssign.label}
                />
              )}
            />
          </Grid>
        </Grid>
        <MDBox mt={1.625}>
          <Grid container spacing={2}>
            {/* Existing Fields */}
            {/* Add your existing fields here */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="standard">
                <InputLabel>Assign Equiment</InputLabel>
                <Select
                  native
                  value={assignequiment}
                  onChange={(e) => {
                    const assignobj =
                      AssignEquipmentList.find(
                        (obj) => obj.sapcode === e.target.value
                      ) || {};
                    setAssignequiment(e.target.value);
                    setAssignEquipmentvalue(assignobj);
                  }}
                  label="Dropdown"
                >
                  <option value=""></option>
                  {AssignEquipmentList?.map((obj) => (
                    <option key={obj?.id} value={obj?.sapcode}>
                      {obj?.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <MDInput
                label="Quantity"
                variant="standard"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MDInput
                label="Asset no"
                variant="standard"
                type="text"
                value={assetno}
                onChange={(e) => setAssetno(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2} mt={2}>
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={handleAdd}
                startIcon={<AddIcon />}
              >
                Add
              </MDButton>
            </Grid>
 
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "10px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <DataTable
                imageMaxWidth={"100px"}
                canSearch={false}
                showTotalEntries={false}
                pagination={false}
                entriesPerPage={false}
                table={{
                  columns: [
                    { Header: "Name", accessor: "item.name" },
                    { Header: "Quantity", accessor: "quantity" },
                    { Header: "Assetno", accessor: "assetno" },
                    {
                      Header: "Actions",
                      accessor: "actions",
                      Cell: ({ row }) => (
                        <span>
                          <FaRegTimesCircle
                            style={{
                              color: "#f44336",
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(row.index)}
                          />
                        </span>
                      ),
                      width: "15%",
                    },
                  ],
                  rows: list?.map((item, index) => ({
                    ...item,
                  })),
                }}
              />
            </Grid>
          </Grid>
        </MDBox>

        <Grid container spacing={2}>
          <Grid item xs={12} mt={2}>
            <MDTypography variant="h4" fontWeight="medium">
              Address Information
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstline.type}
              name={firstline.name}
              value={firstlineV}
              placeholder={firstline.placeholder}
              error={errors.firstline && touched.firstline}
              success={firstlineV.length > 0 && !errors.firstline}
              label=<span>
                {firstline.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={secondline.type}
              name={secondline.name}
              value={secondlineV}
              placeholder={secondline.placeholder}
              label=<span>{secondline.label}</span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropsState}
              value={stateValue}
              onChange={handleStateChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={
                      stateValue != null ||
                      ("" && !errors.state && touched.state)
                    }
                    variant="standard"
                    label=<span>
                      {state.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="state"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              {...defaultPropsDistrict}
              value={districtValue}
              onChange={handleDistrictChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.district && touched.district}
                    success={
                      districtValue != null ||
                      ("" && !errors.district && touched.district)
                    }
                    variant="standard"
                    label=<span>
                      District
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {districtLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="district"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <Autocomplete
              {...defaultPropsSubDistrict}
              value={subdistrictValue}
              onChange={handleSubDistrictChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.subDistrict && touched.subDistrict}
                    success={
                      subdistrictValue != null ||
                      ("" && !errors.subDistrict && touched.subDistrict)
                    }
                    variant="standard"
                    label=<span>
                      {subDistrict.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {subDistrictLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="subDistrict"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <Autocomplete
              {...defaultPropsPincode}
              value={pincodeValue}
              onChange={handlePincodeChange}
              renderInput={(params) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <MDInput
                    {...params}
                    error={errors.pincode && touched.pincode}
                    success={
                      pincodeValue != null ||
                      ("" && !errors.pincode && touched.pincode)
                    }
                    variant="standard"
                    label=<span>
                      {pincode.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {pincodeLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                  <ErrorMessage
                    name="pincode"
                    component="div"
                    style={{
                      color: "red",
                      fontSize: "12px",
                      textAlign: "left",
                      marginTop: "4px",
                    }}
                  />
                </div>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <FormField
              type={country.type}
              name={country.name}
              value={countryV}
              placeholder={country.placeholder}
              error={errors.country && touched.country}
              success={countryV.length > 0 && !errors.country}
              label=<span>
                {country.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            <MDButton
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                const shouldOpenPopup =
                  mapdata.firstline &&
                  mapdata.city &&
                  mapdata.country &&
                  mapdata.state &&
                  values.pincode;
                if (shouldOpenPopup) {
                  setopenpopup(true);
                } else {
                  setopenpopup(false);
                  swal({
                    icon: "warning",
                    title: "Incomplete Data",
                    text: "Please ensure all address fields are filled.",
                  });
                }
              }}
              sx={{
                width: "150px",
                height: "40px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <RemoveRedEyeIcon />
              &nbsp; Add Location
            </MDButton>
            {maplocation && (
              <Grid item xs={12} sm={1} mt={1.5}>
                <CheckCircleIcon fontSize="medium" />
              </Grid>
            )}
          </Grid>
        </Grid>
      </MDBox>

      {openpopup ? (
        <MapPopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Select Location"}
          onDataTransfer={handleDataTransfer}
          data2={mapdata}
        />
      ) : (
        <></>
      )}
    </MDBox>
  );
}

// typechecking props for MachineInfo
LabCreateInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LabCreateInfo;
