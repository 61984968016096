

const form = {
  formId: "new-type-form",
  formField: {
    vaccineName: {
      name: "vaccineName",
      label: "Name of the vaccine",
      type: "text",
      errorMsg: "Vaccine name is required.",
    },
  
    fromAge: {
      name: "fromAge",
      label: "From  Age",
      type: "number",
      errorMsg: "From Age Group is required.",
    },
    toAge: {
      name: "toAge",
      label: "To Age",
      type: "number",
      errorMsg: "To Age Group is required.",
    },
    dependency: {
      name: "dependency",
      label: "Dependency on any other Vaccine ",
      type: "text",
      errorMsg: "Dependency is required.",
    },
    nature: {
      name: "nature",
      label: "Nature of Vaccine ",
      type: "text",
      errorMsg: "Nature of Vaccine is required.",
    },
    applicable: {
      name: "applicable",
      label: "Applicable for which gender ",
      type: "text",
      errorMsg: "Gender is required.",
    },
    brand: {
      name: "brand",
      label: "Available in Brands ",
      type: "text",
      errorMsg: "Brands is required.",
    },
    dosegaplist: {
      name: "dosegaplist",
      label: "Dose Gap List",
      type: "text",
      errorMsg: "Dose Gap List is required.",
    },
    
  
  },
};

export default form;
