 
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
 
import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import { UploadDocumnet } from "Services/UploadDocumnet";
import FileUpload from "react-material-file-upload";
import { useSelector } from "react-redux";
import DataTable from "examples/Tables/DataTable";
import { FormHelperText, Select } from "@mui/material";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import MDButton from "components/MDButton";
import axios from "../../../axiosinstance";
import CircularProgress from "@mui/material/CircularProgress";
 
import TextField from "@mui/material/TextField";
import {  FormControl, InputLabel } from "@mui/material";
import { APIs } from "Services/APIs";
 
const UploadReport = (props) => {
  const { title, selecteddata, openreport, setopenreport, getData } = props;
  const fileInputRef = useRef(null);
 
  const [uploadresponse, setUploadresponse] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [singleloading, setSingleLoading] = useState(false);
  const [testcode, setTestCode] = useState('');
  const [testname, setTestname] = useState('');
  const [categoryoptions, setCategoryOptions] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );
 
 
  function onfileChange(e) {
    if (testname === undefined || testname === '') {
      swal("Oops!", "Please select a test first!", "error");
      return;
    }
    setFiles(e);
    finalupload(e);
  }
 
  async function finalupload(e) {
    // Check if 'e' is not empty and has at least one element
    if (e && e.length > 0) {
      const formData = new FormData();
 
      // Update the formData object
      formData.append("file", e[0]);
      const request = {
        resourcecode: "MEDHARVA",
        username: auth.name,
        model: testcode,
        code: selecteddata?.ordernumber,
      };
      const myJSON = JSON.stringify(request);
      formData.append("body", myJSON);
      setLoading(true);
      const data = await UploadDocumnet(formData);
     const newSno = uploadresponse.length + 1;
       setUploadresponse([
        ...uploadresponse,
        { docid: data?.docid, filename: data?.filename, sno: newSno,testname:testname },
      ]);
 
      setFiles([]);
      setTestCode("")
      setTestname("")
      setLoading(false);
      getData(profileId);
    } else {
      swal("oops!", "Please enter a all field!", "error");
      setLoading(false);
    }
  }
 
  const removeData = async (id) => {
    try {
      const response = await axios.delete(`/new-upload-service-mongo/api/attachment/delete/${id}`);
      console.log(response);
 
      setUploadresponse(prevResponse => prevResponse.filter((item) => item.docid !== id));
    } catch (e) {
      console.error("Error removing data:", e);
    }
  };
 
  
 
  async function bookingUpdatestatuschange() {
    const data = {
      ...selecteddata,
      recstatus: "REPORT UPLOAD",
    };
 
    await axios
      .put(`${APIs.bookingServiceApi}/update`, data)
      .then((response) => {
        swal("Good job!", "Report Upload successfully.", "success");
        getData(profileId);
        handleClose();
      
      })
      .catch((e) => {
        console.log("error", e);
        // setIsLoading(false)
      });
  }
 
  const finalsubmit = (data) => {
    if (data.length === 0) {
      swal("oops!", "Please add atleast one data in table!", "error");
    } else {
     
      bookingUpdatestatuschange();
    }
  };
 
  const handleClose = () => {
    setopenreport(false);
    setUploadresponse([]);
    setTestCode("")
    setTestname("")
   
  };
 
  
  async function getsingledata(id) {
  
    try {
      setSingleLoading(true)
      const response = await axios.get(`new-upload-service-mongo/api/attachment/getAll/code/${selecteddata?.ordernumber}/model/${id}`);
      console.log(response.data);
      if (response.data.length === 0) {
        setSingleLoading(false)
        return;
      } else {
        const existingItem = uploadresponse?.find(item => item?.docid === response?.data?.[0]?.id);
        if (existingItem) {
          setTestname('');
          setTestCode('');
          setSingleLoading(false)
          swal("Oops!", "Already Uploaded please delete it first then upload new!", "error");
        } else {
          const newSno = uploadresponse.length + 1;
          setUploadresponse([
            ...uploadresponse,
            { docid: response?.data?.[0]?.id, filename: response?.data?.[0]?.filename, sno: newSno, testname: response?.data[0]?.model },
          ]);
          setTestname('');
          setTestCode('');
          setSingleLoading(false)
        }
      
      }
    } catch (e) {
      console.log("error", e);
    }
  }
  
 
 
  useEffect(() => {
    if (testname && testname.trim() !== "") {
      getsingledata(testcode);
    }
  }, [testname]);
  
 
  return (
    <Dialog open={openreport} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} mt={4}>
          <Grid item xs={12} sm={6}>
             <select
                      id="category"
                      name="category"
                      value={testcode}
                      onChange={(e) => {
                        const selectedCategoryCode = e.target.value;
                  
       
                        const selectedCategory = selecteddata?.pricelist?.find(
                          (option) => option?.code === selectedCategoryCode
                        );
                        setTestCode(selectedCategoryCode);
                        setTestname(selectedCategory?.pricename);
                        
                      }}
                    
                      style={{ 
                        width:"70%", 
                        padding: "20px",
                        border: "2px solid #ccc",
                        borderRadius: "6px",
                        boxSizing: "border-box",
                        fontSize: "14px",
                      }}
                    >

                     <option value="">Select Lab Test</option>
                {selecteddata?.pricelist?.map((option) => (
                  <option key={option?.id} value={option?.code}>
                    {option?.pricename}
                  </option>
                      ))}
                    </select>
          </Grid>
        </Grid>
        <Grid container spacing={3} mt={2}>
          <Grid item xs={12} sm={12}>
            <FileUpload
              buttonText={
                <span
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <MoonLoader color="#f2fefa" size={16}
                     />
                  ) : (
                    "Upload"
                  )}
                </span>
              }
              value={files}
              onChange={onfileChange}
            />
          </Grid>
 
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "19px",
              marginRight: "5px",
            }}
          >
           {singleloading ? (
            <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          
        }}
      >
       <CircularProgress />
       
      </div>
                  ) : (
                    <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: [
                  { Header: "sno", accessor: "sno" },
                  { Header: "testname", accessor: "testname" },
                  { Header: "name", accessor: "filename" },
                  { Header: "documentid", accessor: "docid" },
                  {
                    Header: "Action",
                    accessor: "uid",
                    Cell: ({ row }) => (
                      <span>
                        <FaRegTimesCircle
                          style={{
                            color: "#f44336",
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => removeData(row.values.docid)}
                        />
                      </span>
                    ),
                    width: "12%",
                  },
                ],
                rows: uploadresponse,
              }}
            />
                  )}
            
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton color="info" size="small" onClick={handleClose}>
          Cancel
        </MDButton>
        <MDButton
          color="info"
          size="small"
          onClick={() => finalsubmit(uploadresponse)}
        >
          Update
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};
 
export default UploadReport;
