import { Suspense, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FindDoctors from "./findDoctors/FindDoctors";
import HomeMobFooter from "./footer/HomeMobFooter";
import HomeDeskFooter from "./footer/HomeDeskFooter";
import OnlineConsultation from "./onlineConsultation/OnlineConsultation";
import LabTest from "./labTest/LabTestHome";
import Medicine from "./Medicine/Medicine";
import Speciality from "components/Shared/specialities/Speciality";
import DoctorsPage from "components/Shared/doctor-bookings/DoctorsPage";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import ForgotPassword from "components/Resuable/ForgotPassword/ForgotPassword";
import ForgotPasswordOtp from "components/Resuable/ForgotPassword/ForgotPasswordOtp";
import ResetPassword from "components/Resuable/ForgotPassword/ResetPassword";
import MobileNavbar from "../../../components/navbar/mobileNavbar/MobileNavbar";
import Navbar from "../../../components/navbar/desktopNavbar/Navbar";
import UnderConstruction from "components/underConstruction/UnderConstruction";
import HomeSkeleton from "components/Skeleton/HomeSkeleton";

function MainHome() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [pageLabel, setPageLabel] = useState("Find Doctor");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const navigate = useNavigate();

  const { slug } = useParams();

  const navigateOnPageRole = () => {
    if (auth && auth?.rolecode === ROLE_CODE.doctorcode) {
      navigate("/app/doctor/profile");
    }
    // else if(auth && auth?.rolecode === ROLE_CODE.patientcode && auth.logincount === "1"){
    //   navigate("/app/patient/patient-information");
    // }
    // else if (auth && auth?.rolecode === ROLE_CODE.patientcode) {
    //   navigate("/app/home/find-doctors");
    // }
    //  else if (auth && auth?.rolecode === ROLE_CODE.nursecode) {
    //   navigate("/app/home/find-doctors");
    // }
     else if (auth && auth?.rolecode === ROLE_CODE.agentcode) {
      navigate("/app/agent/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.miccode) {
      navigate("/app/mic/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.admincode) {
      navigate("/app/admin/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.pharmacycode) {
      navigate("/app/pharmacy/profile");
    }
     else if (auth && auth?.rolecode === ROLE_CODE.cliniccode) {
          navigate("/app/clinic/profile");
        } else if (auth && auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      navigate("/app/lab/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.collectiveagentcode) {
      navigate("/app/collectiveagent/profile");
    }
    // else {
    //   navigate("/app/home/find-doctors");
    // }
  };
  useEffect(() => {
    if (!slug) {
      navigateOnPageRole();
    }
  }, [auth?.rolecode]);
  useEffect(() => {
    navigateOnPageRole();
  }, [auth]);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    {
      label: "Find Doctors",
      slug: "find-doctors",
    },
    {
      label: "Online Consultation",
      slug: "online-consultation",
    },
    {
      label: "Lab Test",
      slug: "lab-test",
    },
    {
      label: "Medicines",
      slug: "medicine",
    },
    {
      label: "Medharva Care",
      slug: "medharva-care",
    },
    {
      label: "For Corporates",
      slug: "for-corporates",
    },
    {
      label: "For Providers",
      slug: "for-providers",
    },
  ];

  useEffect(() => {
    for (let i = 0; i < data?.length; i++) {
      if (data?.[i]?.slug === slug) {
        setPageLabel(data?.[i]?.label);
      }
    }
  }, [slug]);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
       <Suspense fallback={<HomeSkeleton />}>
      {["forgot-password", "forgot-paasword-otp", "reset-password"].includes(
        slug
      ) ? null : isMidScreen ? (
        <MobileNavbar />
      ) : (
        <Navbar pagelabel={pageLabel} />
      )}

      {slug === "find-doctors" && <FindDoctors pagelabel={pageLabel} />}
      {slug === "online-consultation" && (
        <OnlineConsultation pagelabel={pageLabel} />
      )}
      {slug === "lab-test" && <LabTest />}
      {slug === "medicine" && <Medicine />}

      {["for-corporates", "medharva-care", "for-providers"].includes(slug) && (
        <div style={{ marginTop: "150px" }}>
          <UnderConstruction />
        </div>
      )}

      {slug === "speciality" && <Speciality />}
      {slug === "doctor-booking" && <DoctorsPage />}
      {slug === "forgot-password" && <ForgotPassword />}
      {slug === "forgot-paasword-otp" && <ForgotPasswordOtp />}
      {slug === "reset-password" && <ResetPassword />}

      {["forgot-password", "forgot-paasword-otp", "reset-password"].includes(
        slug
      ) ? null : isMidScreen ? (
        <HomeMobFooter pagelabel={pageLabel} />
      ) : (
        <HomeDeskFooter />
      )}
      </Suspense>
    </div>
  );
}

export default MainHome;
