
import * as React from "react";
import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";


import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import { useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance";
//Material UI

import { getData } from "Services/Slot";
import moment from "moment-timezone";


import { Tooltip } from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "./style.css";
import CircularProgress from "@mui/material/CircularProgress";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import { APIs } from "Services/APIs";
import SotCreation from "./SlotCreation";

function SlotList(props) {
 
  const [response, setResponse] = useState();
  const [profileid, setProfileId] = useState(
    props.doctorid ? props.doctorid : props.data
  );

  const [date, setDate] = useState(moment(new Date()).format("yyyy-MM-DD"));
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [slotlist, setSlotlist] = useState([]);
  const {getMMUDetails, getProjectDetails} = useSelector(state => state);
  const [disabled, setDisabled] = useState(props.disabled);
  const [popup, setPopup] = useState(false);
  const [accordianValue, setAccordian] = useState(props.value);

  const [isLoading, setIsLoading] = useState(false);
 

  var finalTimeArray = [];
  const [time, setTime] = useState(finalTimeArray);

  var intialCheckBox = {};
  const [checkboxstatus, setCheckboxstatus] = useState({});

  const navigate = useNavigate();

  var obj = checkboxstatus;
  var keys = Object.keys(obj).filter((e) => obj[e] === true);

  async function getSlotData(id, date) {
    setSlotlist([]);
    setIsLoading(true);

    var checkbox = {};
    const api = ROLE_CODE?.cliniccode === auth?.rolecode  === auth?.rolecode ? `${APIs.eventServiceApi}/get?date=${date}&mmucode=${getProjectDetails?.details?.mmucreation?.code}`:`${APIs.eventServiceApi}/get?profileid=${id}&date=${date}`;
    await axios
      .get(api)
      .then((response) => {
        response?.data?.data?.dtoList?.map((values) => {
          checkbox[values.startdate] = false;
          startDate(response.data?.data?.dtoList);
          var date = values.startdate;
          var timeonly = date.substring(11, 19);
          var hms = timeonly; // your input string
          var a = hms.split(":"); // split it at the colons
          var correctTime = ("a", a[0] + ":" + a[1]);

          values.slottime = correctTime;
          // minutes are worth 60 seconds. Hours are worth 60 minutes.

          values.averageusercount = values.averageusercount
            ? values.averageusercount
            : 0;
          if (values.averageusercount * 1 == 0) {
            values.button = true;
          } else {
            values.button = false;
          }
        });
        setCheckboxstatus(checkbox);
        setSlotlist(response.data?.data?.dtoList);

        props.getData(response.data?.data?.dtoList);

        // if(response.data?.data?.listSize === 0){
        //   swal("", "No slot data is present on this date.", "warning");
        // }
      })

      .catch((e) => {
        // swal("", "Something went wrong", "warning");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function startDate(slotvalue) {
    for (let i = 0; i < slotvalue.length; i++) {
      setTime(slotvalue[i].startdate);
    }
  }

  const handleChange = (event, object) => {
    if (event.target.name === "all") {
      // const newObj: any = objectMap(state, (value) => event.target.checked);
      Object.keys(checkboxstatus).forEach(
        (key) => (checkboxstatus[key] = event.target.checked)
      );
      setCheckboxstatus({ ...checkboxstatus });
    } else {
      // object[event.target.name] = event.target.checked
      // setCheckboxstatus(checkboxstatus)
      setCheckboxstatus({
        ...object,
        [event.target.name]: event.target.checked,
      });
    }
  };

  function getResponse(e) {
    const selectedDate = moment(e.target.value, "YYYY-MM-DD");
    const today = moment().startOf("day");
  
    if (selectedDate.isBefore(today)) {

      swal("","You cannot select a date in the past.","warning");
    } else {
      setDate(e.target.value);
      var y = getData(" ");
      setResponse(y);
      intialCheckBox = {};
      var date = dateFormat(e.target.value);
      getSlotData(profileid, date);
    }
  }

  function dateFormat(date) {
    return moment(date).format("yyyy/MM/DD");
  }
  // format date and time in am pm
  function formatTime(timeString) {
    const formattedTime = moment(timeString, "HH:mm").format("h:mm A");
    return formattedTime;
  }
  function handleAddButton() {

    if(ROLE_CODE?.cliniccode === auth?.rolecode && getProjectDetails.details.mmucreation === null){
      swal("You can not perform this action, because you are not assign with lab", {
        icon: 'error',
      })
      return;
    }

    setPopup(true);
  
  }
  useEffect(() => {
    getSlotData(profileid, dateFormat(new Date()));
  }, []);

  return (
    <>
    <Card>
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <MDBox>
              {disabled ? (
                <MDTypography variant="h5" fontWeight="bold">
                  Slot List
                </MDTypography>
              ) : (
                <MDTypography variant="h5" fontWeight="bold">
                  Slot Booking
                </MDTypography>
              )}
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={2}>
            {disabled ? (
              <MDBox>
                <MDButton
                  color="info"
                  size="small"
                  // onClick={() =>
                  //   navigate(linkTo)
                  // }
                  onClick={handleAddButton}
                >
                  CREATE SLOT
                </MDButton>
              </MDBox>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <MDBox>
          <Grid container spacing={3} m={3}>
            <Grid item xs={12} sm={8}>
              <MDInput
                value={date}
                type="date"
                onChange={(e) => getResponse(e)}
                sx={{ marginRight: "20px" }}
              />
              {isLoading ? (
                <CircularProgress size="25px" style={{ marginTop: "7px" }} />
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {slotlist.length > 0 && accordianValue == "accordianData" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        Object.values(checkboxstatus).filter(
                          (value) => value !== true
                        ).length < 1
                      }
                      onChange={(e) => handleChange(e, checkboxstatus)}
                      name="all"
                    />
                  }
                  label=" Select All"
                />
              )}
            </Grid>
          </Grid>

          <MDBox p={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <LightModeIcon fontSize="large" />
              </Grid>

              <Grid item xs={12} sm={10}>
                <Grid container spacing={3}>
                  {slotlist.length > 0 ? (
                    slotlist.map((obj) => {
                      return (
                        <Grid item xs={12} sm={2}>
                          {disabled === true ? (
                            <Tooltip title={obj.averageusercount} arrow>
                              <MDButton
                                name={obj.slottime}
                                disabled={obj.button}
                                variant="outlined"
                                color="info"
                                size="small"
                              >
                                {obj.slottime}
                              </MDButton>
                            </Tooltip>
                          ) : disabled === false ? (
                            <Tooltip title={obj.averageusercount} arrow>
                              <MDButton
                                name={obj.slottime}
                                disabled={obj.button}
                                onClick={() => {
                                  navigate(
                                    `/app/page/final-appointment?id=${props.doctorid}`,
                                    {
                                      state: {
                                        date: date,
                                        time: formatTime(obj.slottime),
                                        profileid: profileid,
                                        slotobject: obj,
                                        doctorobject: props?.doctorobject,
                                        doctorfess: props?.doctorfess,
                                        patientrecpanist:
                                          props?.patientrecpanist,
                                      },
                                    }
                                  );
                                }}
                                variant="outlined"
                                color="info"
                                size="small"
                              >
                                {formatTime(obj.slottime)}
                              </MDButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                          <Grid container spacing={3}>
                            {accordianValue === "accordianData" && (
                              <Tooltip title={obj.averageusercount} arrow>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkboxstatus[obj.startdate]}
                                      onChange={(e) =>
                                        handleChange(e, checkboxstatus)
                                      }
                                      name={obj.startdate}
                                    />
                                  }
                                  label={
                                    <button
                                      name={obj.slottime}
                                      disabled={obj.button}
                                      className="btn"
                                    >
                                      {obj.slottime}
                                    </button>
                                  }
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })
                  ) : (
                    <Grid item xs={12} sm={12}>
                    {isLoading ? (
               <></>
              ) : (
                <div>

<h3>Appointment Slots</h3>
<p>No slots have been created by the clinic for the date {date}</p>


</div>
              )}
                   
                      {/* <MDTypography variant="h6" color="warning">No slot data is present on this date.</MDTypography> */}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
    {popup && (
        <SotCreation
        popup={popup}
        setPopup={setPopup}
        getSlotData={getSlotData}
        dateFormat={dateFormat}
        profileid={profileid}
        />
      )}
    </>
  );
}

export default SlotList;
