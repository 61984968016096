import { useState, useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import Divider from "@mui/material/Divider";
import PreviewIcon from "@mui/icons-material/Preview";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

// OrderDetails page components

import MDTypography from "components/MDTypography";

import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";

import PaymentDetails from "components/Resuable/detailcomponet/components/PaymentDetails";

import OrderSummary from "components/Resuable/detailcomponet/components/OrderSummary";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import Table from "@mui/material/Table";

import TableRow from "@mui/material/TableRow";

import TableBody from "@mui/material/TableBody";
import axios from "../../../axiosinstance";

import Header from "./Header";
import DownloadIcon from "@mui/icons-material/Download";

import OrderInfo from "./OrderInfo";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import PaymentDetail from "./PaymentDetail";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import MDButton from "components/MDButton";
import { downloadurl } from "../../../Services/UploadDocumnet";
import CircularProgress from "@mui/material/CircularProgress";
import { TableCell } from "@mui/material";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { useSelector } from "react-redux";
import LabReport from "app/lab/labReport/LabReport";
import { ROLE_CODE } from "privateComponents/codes";

function BookingView(props) {
  const { title, openpopup, setopenpopup, selectedData } = props;
  const [reportresult, setReportresult] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [reportPopup, setReportPopup] = useState(false);
  const [testName, setTestName] = useState(null);
  const [orderNumber, setOrderNumber] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  const handleReportPopup = (testname, ordernumber) => {
    setReportPopup(true);
    setTestName(testname);
    setOrderNumber(ordernumber);
  };

  const handleClose = () => {
    setopenpopup(false);
  };

  const selecteddata = openpopup ? selectedData : location?.state?.selecteddata;

  async function getReport(ordernumber) {
    setLoading(true);
    await axios
      .get(
        `new-upload-service-mongo/api/attachment/getAll/code/${ordernumber}`
      )
      .then((response) => {
        setReportresult(response?.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  const handleDownload = (filename, name) => {
    swal({
      title: "Confirm Download",
      text: `Do you want to download ${name}?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        confirm: "Download",
      },
    }).then((willDownload) => {
      if (willDownload) {
        fetch(`${downloadurl}/${filename}`)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = name;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              URL.revokeObjectURL(blobUrl);
            }, 100);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            swal(
              "Error",
              "An error occurred while downloading the file.",
              "error"
            );
          });
      } else {
        swal("Download Cancelled", "You have cancelled the download.", "info");
      }
    });
  };

  const handleDownload2 = (response, testname) => {
    swal({
      title: "Are you sure?",
      text: `Do you want to download this ${testname} report?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDownload) => {
      if (willDownload) {
        const element = document.getElementById("reportContent");

        if (element) {
          const originalOverflow = element.style.overflow;
          const originalWhiteSpace = element.style.whiteSpace;

          element.style.overflow = "visible";
          element.style.whiteSpace = "nowrap";

          const dataTableElements = element.querySelectorAll(
            ".MuiTableContainer-root"
          );
          const originalStyles = [];

          dataTableElements.forEach((dataTableElement) => {
            originalStyles.push({
              overflowX: dataTableElement.style.overflowX,
              overflowY: dataTableElement.style.overflowY,
              maxHeight: dataTableElement.style.maxHeight,
            });

            dataTableElement.style.overflowX = "hidden";
            dataTableElement.style.overflowY = "hidden";
            dataTableElement.style.maxHeight = "none";
          });

          domtoimage
            .toPng(element, { useCORS: true })
            .then((imgData) => {
              const pdf = new jsPDF("p", "mm", "a4");

              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = pdf.internal.pageSize.getHeight();
              const margin = 10;

              const img = new Image();
              img.src = imgData;
              img.onload = () => {
                const imgWidth = pdfWidth - 2 * margin;
                const imgHeight = (img.height * imgWidth) / img.width;

                if (imgHeight > pdfHeight - 2 * margin) {
                  const scaleFactor = (pdfHeight - 2 * margin) / imgHeight;
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth * scaleFactor,
                    imgHeight * scaleFactor
                  );
                } else {
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth,
                    imgHeight
                  );
                }

                pdf.save(`${response?.patientobj?.name}_${testname}.pdf`);
              };

              dataTableElements.forEach((dataTableElement, index) => {
                dataTableElement.style.overflowX =
                  originalStyles[index].overflowX;
                dataTableElement.style.overflowY =
                  originalStyles[index].overflowY;
                dataTableElement.style.maxHeight =
                  originalStyles[index].maxHeight;
              });

              element.style.overflow = originalOverflow;
              element.style.whiteSpace = originalWhiteSpace;
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
            });
        } else {
          console.error("Report reference is null.");
        }
      }
    });
  };

  useEffect(() => {
    getReport(selecteddata?.ordernumber);
  }, [selecteddata]);

  const BookingContent = () => {
    return (
      <>
        <MDBox pt={2} px={2}>
          <Header
            orderNo={selecteddata?.ordernumber}
            datetime={selecteddata?.slot?.startdate}
          />
        </MDBox>

        <Divider />

        <MDBox pt={1} pb={3} px={2}>
          <MDBox mb={3}>
            <OrderInfo
              doctorData={selecteddata?.mmu}
              price={selecteddata?.pricelist}
              ordernumber={selecteddata?.ordernumber}
              date={selecteddata?.slotdate}
              paymentmethod={selecteddata?.paymentmethod}
            />
          </MDBox>

          <Divider />

          <MDBox mt={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={5}>
                <PaymentDetail paymentmethod={selecteddata?.paymentmethod} />

                {/* <MDBox mt={3}>
 
                        <BillingInformation />
 
                      </MDBox> */}
              </Grid>

              {/* <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
 
                      <OrderSummary />
 
                    </Grid> */}
            </Grid>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={12} md={16}>
                  <MDBox p={0.8}>
                    <MDBox width="100%" overflow="auto">
                      <Table sx={{ minWidth: "32rem" }}>
                        <MDBox component="thead">
                          <TableRow style={{ backgroundColor: "#039147" }}>
                            <MDBox
                              component="th"
                              width={{ xs: "45%", md: "50%" }}
                              py={1.5}
                              px={2}
                              textAlign="left"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                Test Name
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              component="th"
                              width={{ xs: "45%", md: "50%" }}
                              py={1.5}
                              px={1}
                              textAlign={openpopup ? "center" : "left"}
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                Test Mode
                              </MDTypography>
                            </MDBox>
                            <MDBox
                              component="th"
                              width={{ xs: "45%", md: "50%" }}
                              py={1.5}
                              px={1}
                              textAlign="center"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                Download Report
                              </MDTypography>
                            </MDBox>

                            <MDBox
                              component="th"
                              width={{ xs: "45%", md: "50%" }}
                              py={1.5}
                              px={1}
                              textAlign="center"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                View Report
                              </MDTypography>
                            </MDBox>

                            <MDBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                {/* Qty */}
                              </MDTypography>
                            </MDBox>

                            <MDBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                Rate
                              </MDTypography>
                            </MDBox>

                            <MDBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={2}
                              textAlign="left"
                              sx={borderBottom}
                            >
                              <MDTypography
                                variant="h6"
                                color="white"
                                fontWeight="medium"
                              >
                                Amount
                              </MDTypography>
                            </MDBox>
                          </TableRow>
                        </MDBox>

                        <TableBody>
                          {selecteddata?.pricelist?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell textAlign="left" sx={borderBottom}>
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  {item?.pricename}
                                </MDTypography>
                              </TableCell>

                              <TableCell textAlign="left" sx={borderBottom}>
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  {item?.type}
                                </MDTypography>
                              </TableCell>

                              {/* Check if there is a matching reportresult with the same code */}
                              <TableCell align="center" sx={borderBottom}>
                                {reportresult?.some(
                                  (url) => url.model === item.code
                                ) ? (
                                  reportresult?.map((url, idx) =>
                                    url.model === item.code ? (
                                      <DownloadIcon
                                        key={idx}
                                        fontSize="medium"
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDownload(url?.id, url?.filename)
                                        }
                                      />
                                    ) : null
                                  )
                                ) : selecteddata?.categorylist?.some(
                                    (category) =>
                                      category.parentdepcode === item.code
                                  ) ? (
                                  <DownloadIcon
                                    fontSize="medium"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                    onClick={async () => {
                                      // await navigate(
                                      //   "/app/lab/test-report-View",
                                      //   {
                                      //     state: {
                                      //       testname: item?.pricename,
                                      //       ordernum: selecteddata?.ordernumber,
                                      //     },
                                      //   }
                                      // );
                                      handleReportPopup(
                                        item?.pricename,
                                        selecteddata?.ordernumber
                                      );
                                      await handleDownload2(
                                        selecteddata,
                                        item?.pricename
                                      );
                                    }}
                                  />
                                ) : (
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    Report unavailable
                                  </MDTypography>
                                )}
                              </TableCell>

                              <TableCell align="center" sx={borderBottom}>
                                {reportresult?.some(
                                  (url) => url.model === item.code
                                ) ? (
                                  reportresult
                                    ?.filter((url) => url.model === item.code)
                                    ?.map((url, idx) => (
                                      <PreviewIcon
                                        key={idx}
                                        fontSize="medium"
                                        style={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          window.open(url.downloadurl)
                                        }
                                      />
                                    ))
                                ) : selecteddata?.categorylist?.some(
                                    (category) =>
                                      category.parentdepcode === item.code
                                  ) ? (
                                  <PreviewIcon
                                    fontSize="medium"
                                    style={{
                                      color: "green",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      // navigate(
                                      //   `/app/${auth?.rolename.toLowerCase()}/test-report-View`,
                                      //   {
                                      //     state: {
                                      //       testname: item?.pricename,
                                      //       ordernum: selecteddata?.ordernumber,
                                      //     },
                                      //   }
                                      // )
                                      handleReportPopup(
                                        item?.pricename,
                                        selecteddata?.ordernumber
                                      )
                                    }
                                  />
                                ) : (
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    Report unavailable
                                  </MDTypography>
                                )}
                              </TableCell>

                              <TableCell align="center" sx={borderBottom}>
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  {/* Display whatever details you need */}
                                </MDTypography>
                              </TableCell>

                              <TableCell align="center" sx={borderBottom}>
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  ₹ {item?.value}
                                </MDTypography>
                              </TableCell>

                              <TableCell align="center" sx={borderBottom}>
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  ₹ {item?.value}
                                </MDTypography>
                              </TableCell>
                            </TableRow>
                          ))}

                          <TableRow>
                            <TableCell textAlign="left" sx={borderBottom}>
                              <MDTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                {/* {item?.code} */}
                              </MDTypography>
                            </TableCell>

                            {/* Check if there is a matching reportresult with the same code */}
                            <TableCell align="center" sx={borderBottom}>
                              {reportresult?.some(
                                (url) => url.model === "1"
                              ) ? (
                                reportresult?.map((url, idx) =>
                                  url.model === item.code ? (
                                    <DownloadIcon
                                      key={idx}
                                      fontSize="medium"
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handleDownload(url?.id)}
                                    />
                                  ) : null
                                )
                              ) : (
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  {/* Report not available */}
                                </MDTypography>
                              )}
                            </TableCell>

                            <TableCell align="center" sx={borderBottom}>
                              {reportresult?.some(
                                (url) => url.model === "1"
                              ) ? (
                                reportresult?.map((url, idx) =>
                                  url.model === item.code ? (
                                    <PreviewIcon
                                      key={idx}
                                      fontSize="medium"
                                      style={{
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        window.open(url.downloadurl)
                                      }
                                    />
                                  ) : null
                                )
                              ) : (
                                <MDTypography
                                  variant="body2"
                                  color="text"
                                  fontWeight="regular"
                                >
                                  {/* Report not available */}
                                </MDTypography>
                              )}
                            </TableCell>

                            <TableCell align="center" sx={borderBottom}>
                              <MDTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                {/* Display whatever details you need */}
                              </MDTypography>
                            </TableCell>

                            <TableCell align="center" sx={borderBottom}>
                              <MDTypography
                                variant="body2"
                                color="text"
                                fontWeight="regular"
                              >
                                {/* Display whatever details you need */}
                              </MDTypography>
                            </TableCell>

                            <TableCell align="center" sx={borderBottom}>
                              <MDTypography variant="h5">Total</MDTypography>
                            </TableCell>

                            <TableCell align="center" sx={borderBottom}>
                              <MDTypography variant="h6">
                                ₹{" "}
                                {selecteddata?.pricelist?.reduce(
                                  (acc, item) => acc + parseInt(item?.value),
                                  0
                                )}
                              </MDTypography>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            )}
          </MDBox>
        </MDBox>
      </>
    );
  };

  return (
    // <DashboardLayout>
    // <UpaarNavbar/>
    <>
      {openpopup ? (
        <Dialog open={openpopup} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle
            style={{
              backgroundColor: "#039147",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {title}
          </DialogTitle>

          <BookingContent />

          <DialogActions>
            <MDButton
              variant="gradient"
              color="success"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </MDButton>
          </DialogActions>
        </Dialog>
      ) : (
        <>
          <MDBox pt={3} mb={3}>
            <MDBox mt={auth?.rolecode === ROLE_CODE?.patientcode ? isMidScreen ? 23 : 20 : isMidScreen ? 10 : 6}>
              <Card style={{ margin: auth?.rolecode === ROLE_CODE?.patientcode && "0px 22px" }}>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <BookingContent />
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
        </>
      )}
      {reportPopup && (
        <LabReport
          openpopup={reportPopup}
          setopenpopup={setReportPopup}
          testName={testName}
          orderNumber={orderNumber}
          mmucode={selecteddata?.mmu?.code}
        />
      )}
    </>
  );
}

export default BookingView;
