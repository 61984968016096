import React, { useState } from "react";

// import google from "../../findDoctor/assets/images/Google.svg";
// import facebook from "../../findDoctor/assets/images/Facebook2.svg";
// import twitter from "../../findDoctor/assets/images/twitter.svg";
import "./signup.css";
import { Grid} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { MoonLoader } from "react-spinners";
import axios from "../../../axiosinstance";

const SignUp = ({ onData }) => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address.")
      .required("Email is required."),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });
 
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    const formData = {
      emailid: values.email,

      id: null,
      isactive: "ACTIVE",

      rolename: "PATIENT",
      rolecode: "PA001",
      logincount: "1",
      password: values.password,

      recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",

      createdby: "HEALTHCARE WEB UI",
    };

    await axios
      .post("login-service-mongo/api/loginservice/v2/create", formData)

      .then((response) => {
        swal(
          "Registration Successful!",
          "Your account has been created successfully.",
          "success"
        );
        onData("Login");
        resetForm();
      })
      .catch((error) => {
        console.log(error);
        swal("Oops!", 
          (error?.response && error.response?.data && error?.response?.data?.aceErrors) ?
            (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
              "User Already Registered with the same Email id !!" :
              error?.response?.data?.aceErrors?.[0]?.errorMessage) :
            "Request failed with status code 404.",
          "warning"
        );
      
     
        resetForm();
        setIsLoading(false);
      });
    setSubmitting(false);
    setIsLoading(false);
  };
  return (
    <Formik
      initialValues={{ email: "", password: "", confirmpassword: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="input-block">
          <label htmlFor="email">Email:</label>
          <Field type="text" id="email" name="email" />
          {
            <p
              style={{
                fontWeight: "400",
                color: "red",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <ErrorMessage name="email" component="div" />
            </p>
          }
        </div>
        <div className="input-block">
          <label htmlFor="password">Password:</label>
          <Field type="password" id="password" name="password" />
          {
            <p
              style={{
                fontWeight: "400",
                color: "red",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <ErrorMessage name="password" component="div" />
            </p>
          }
        </div>
        <div className="input-block">
          <label htmlFor="confirmpassword">Confirm Password:</label>
          <Field type="password" id="confirmpassword" name="confirmpassword" />
          <p
            style={{
              fontWeight: "400",
              color: "red",
              fontSize: "12px",
              textAlign: "left",
            }}
          >
            <ErrorMessage name="confirmpassword" component="div" />
          </p>
        </div>
        <Grid mt={5} mb={5}>
        <button type="submit" className="btn-login" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Sign Up"}
        </button>
        </Grid>

        {/* <div className="loginwith">Or login with</div> */}
        {/* <div className="logoimagecontainer">
          <a href="your_link_url">
            <img className="loginimageitem" src={google} alt="Logo" />
          </a>

          <img className="loginimageitem" src={facebook} alt="Logo" />
          <img className="loginimageitem" src={twitter} alt="Logo" />

         
        </div> */}
      </Form>
    </Formik>
  );
};

export default SignUp;
