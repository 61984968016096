import { useState,useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { APIs } from 'Services/APIs'
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axiosinstance";
import Dialog from "@mui/material/Dialog";

import * as Yup from "yup";
import EditVaccinemaster from "./EditVaccinemaster";



// Function to define form steps
function getSteps() {
  return ["Create Vaccine Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <EditVaccinemaster formData={formData} />;
    default:
      return null;
  }
}


export default function EditVaccine(props) {
  const { openpopup2, setopenpopup2,handleClose2,handleCreatereset ,selectedUser} = props;
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false); 
    const steps = getSteps();
    const { formId, formField } = form;
   
    const isLastStep = activeStep === steps.length - 1;
    const { error, loading } = useSelector((state) => state.disease);
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer); 
    const sleep = (ms) =>
      new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
  
    const handleBack = () => setActiveStep(activeStep - 1);
    const dispatch = useDispatch();

    const initialValues = {
        vaccineName: selectedUser?.name || "",
        fromAge: selectedUser?.age_from || "",
        toAge: selectedUser?.age_to || "",
        dependency: selectedUser?.dependency  || [],
        nature: selectedUser?.nature || "",
        // applicable: selectedUser?.applicable || '',
        // brand: selectedUser?.brands || [],
        applicable: Array.isArray(selectedUser?.applicable) ? selectedUser.applicable : '',
        brand: Array.isArray(selectedUser?.brands) ? selectedUser.brands : [],
      
        dosegaplist: selectedUser?.dose_gap_list
        || [],
      };
      const validationSchema = Yup.object({
        vaccineName: Yup.string().required("Vaccine name is required"),
        // fromAge: Yup.number()
        //   .required("From Age is required")
        //   .max(Yup.ref(toAge), "From Age must be less than or equal to To Age"),
        toAge: Yup.number().required("To Age is required"),
        applicable: Yup.mixed()
        .transform((value) => (typeof value === "string" ? [value] : value)) // String ko array bana raha hai
        .test("is-array", "Applicable field must be an array", (value) => Array.isArray(value))
        .nullable()
        .required("Applicable field is required")
        .test("min-length", "At least one applicable option is required", (value) => value && value.length > 0),
     
        nature: Yup.string().required("Nature is required"),
        brand: Yup.array().min(1, "At least one brand is required").required(),
      });

    const submitForm = async (values, actions) => {
      if (values.dosegaplist.length === 0) {
        swal("", "Please add data in table first!", "warning");
        return;
      }
  
      setIsLoading(true);
    
      const requestData = {
       ...selectedUser,
        name: values.vaccineName,
        age_from:values.fromAge,
        age_to:values.toAge,
        nature:values.nature,
        dependency:values?.dependency?.length ? values.dependency : null,
        applicable: Array.isArray(values.applicable) 
        ? values.applicable 
        : [values.applicable],
        brands: Array.isArray(values.brand) 
        ? values.brand 
        : [values.brand],
        dose_gap_list:values.dosegaplist,
        modifiedby: auth.profileid,  
        modifiedbyname: auth.name, 
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };
    console.log("requestData110:",requestData)
      try {
        const response = await axios.put(`${APIs.vaccineMasterServiceApi}/update`, requestData);
    
   

    
        if (!response.error) {
          setIsLoading(false);
          handleClose2();
          swal("Success!", "Your data has been successfully Updated.", "success");
          handleCreatereset();
          setActiveStep(activeStep - 1);
        }
      } catch (error) {
      
    
        setIsLoading(false);
        swal("Oops!", error?.response?.data?.aceErrors[0]?.errorMessage || "Something went wrong.", "error");
      }
    };
    
    // Function to handle form submission
    const handleSubmit = (values, actions) => {
  
  
      if (isLastStep) {
        // If it's the last step, submit the form
        submitForm(values, actions);
      } else {
        // If not the last step, move to the next step
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    };
   


 

  // Render the component
  return (

    <Dialog open={openpopup2} onClose={handleClose2} fullWidth maxWidth="md">
    
    <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Edit Vaccine 
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                         
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Update"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    
  );
}