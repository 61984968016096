import {
    Button,
    Card,
    FormControlLabel,
    Stack,
    Switch,
    Tooltip,
  } from "@mui/material";
  import FilterCmp from "components/Filters/FilterCmp.tsx";
  import DataTable from "components/Tables/DataTable";
  import React, { useEffect } from "react";
  import { useState } from "react";
  import { useDispatch } from "react-redux";
  import { useSelector } from "react-redux";
  import { tableDataAction } from "redux/Actions/tableDataAction";
  import { directoryFilter } from "static/filters/directoryFilter";
  import TableCmp from "components/Tables/TableCmp";
  import { APIs } from "Services/APIs";
  import Swal, { SweetAlertOptions } from "sweetalert2";
  import tableActionRow, {
    clearActionOfRow,
    clearActionRowData,
    setActionTypeRow,
  } from "redux/slices/tableActionRow";
  import { useLocation, useNavigate } from "react-router-dom";
  import axios from "../../../../axiosinstance";
  import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
  import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
  import DashboardLayout from "components/DashboardLayout";
  import MDBox from "components/MDBox";
  import MDTypography from "components/MDTypography";
  import MDButton from "components/MDButton";
  import { deleteProjectServiceData } from "redux/Actions/getProjectServiceData";
  import { FaRegEdit } from "react-icons/fa";
  import { MdDelete } from "react-icons/md";

  import { micappCode } from "static/micappCode";
import ClinicTeamassociationPopup from "./ClinicTeamassociationPopup";
import { clinicappCode } from "static/labappCode";
 
  
  const ClinicTeamAssociationList = () => {
    const { tableData, AuthReducer, tableActionRow } = useSelector(
      (state) => state
    );
    const { loading } = useSelector((state) => state.tableData);
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState();
    const [filterData, setFilterData] = useState({});
    const [perPage, setPerPage] = useState(5);
    const location = useLocation();
    const [openpopup, setopenpopup] = useState(false);
    const [openassociation, setOpenassociation] = useState(false);
    const [selecteddata, setSelecteddata] = useState(null);
    const nameFilter = new URLSearchParams(location.search).get("name");
    const addButtonStyle = {
      display: "flex",
      fontSize: "2em",
      justifyContent: "space-between",
      alignItems: "center",
    };
    const iconStyleedit = {
      fontSize: "1.5em",
      cursor: "pointer",
      color: "#039147",
    };
    const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };
  
    const filters = [
      {
        type: "text",
        placeholder: "Enter name",
        variant: "outlined",
        label: "Name",
        name: "name",
        page: "allList",
      },
    ];
    const toggler = async (checked, item) => {
      try {
        const updatedUser = {
          ...item,
          recstatus: checked ? "APPROVED" : "PENDING",
        };
  
        await axios.put(`${APIs.mmuteamAssociation}/update`, updatedUser);
  
        dispatch(
          tableDataAction({
            url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    const actionButtons = (row) => {
      return (
        <Stack
          direction="row"
          sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
        >
          {/* <Tooltip title="Edit">
              <div>
                <FaRegEdit
                  style={iconStyleedit}
                  onClick={() => {
                    dispatch(setActionTypeRow({ type: "edit", data: row }));
                    handleOpenEditDialog(row);
                  }}
                />
              </div>
            </Tooltip> */}
          <Tooltip title="Delete">
            <div>
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  dispatch(setActionTypeRow({ type: "edit", data: row }));
                  onDelete(row);
                }}
              />
            </div>
          </Tooltip>
        </Stack>
      );
    };
  
    useEffect(async () => {
      setFilterData({ ...filterData, name: nameFilter });
      if (nameFilter) {
        dispatch(
          tableDataAction({
            url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter || filterData.name}`,
          })
        );
      }
    }, [dispatch]);
  
    useEffect(() => {
      if (filterData?.name || nameFilter) {
        dispatch(
          tableDataAction({
            url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter || filterData.name}`,
          })
        );
      } else {
        dispatch(
          tableDataAction({
            url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      }
    }, [currentPage, perPage]);
  
    const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
      return {
        ...row,
  
        action: actionButtons(row),
      };
    });
  
    const columns = [
      { id: "mmuname", label: "Clinic Name" },
      { id: "fullname", label: "User Name" },
      { id: "rolename", label: "Role" },
      { id: "createdbyname", label: "Created By" },
      { id: "action", label: "Action" },
    ];
  
    const handleSearch = () => {
      setCurrentPage(1);
      if (filterData?.name) {
        dispatch(
          tableDataAction({
            url: `${
              APIs.mmuteamAssociation
            }/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${0}&size=${perPage}&st=${
              filterData.name
            }`,
          })
        );
      }
    };
    const passData = () => {
      dispatch(
        tableDataAction({
          url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    };
    const handleClear = () => {
      setFilterData({});
      // navigate("/app/mic/create-mic")
      dispatch(
        tableDataAction({
          url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    };
    const handleOpenEditDialog = (row) => {
      setSelecteddata(row);
      setopenpopup(true);
    };
    async function onDelete(row) {
      const willDelete = await swal({
        title: "Are you sure?",
        text: `You will not be able to recover this ${row?.mmuname}!`,
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      });
  
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
  
        try {
          await axios.put(`${APIs.mmuteamAssociation}/delete`, row);
          swal("Deleted!", "Your item has been deleted.", "success");
  
          dispatch(
            tableDataAction({
              url: `${APIs.mmuteamAssociation}/get?type=${clinicappCode.mappingcode}&ispageable=true&page=${
                currentPage - 1
              }&size=${perPage}`,
            })
          );
        } catch (error) {
          swal("Error", "An error occurred while deleting the item.", "error");
        }
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    }
  
    return (
      <DashboardLayout>
        {loading ? (
          <Card>
            <>
              <SkeletonFilterCmp filterArray={filters} />
              <SkeletonTableCmp columns={columns} perPage={perPage} />
            </>
          </Card>
        ) : (
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Clinic Team Association List
                  </MDTypography>
                </span>
  
                <span
                  style={{
                    alignSelf: "start",
                    color: "#344767",
                  }}
                >
                  <MDButton
                    color="success"
                    onClick={() => setOpenassociation(true)}
                  >
                    ADD
                  </MDButton>
                </span>
              </div>
            </MDBox>
            <FilterCmp
              filterArray={filters}
              filterData={filterData}
              setFilterData={setFilterData}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
            <TableCmp
              columns={columns}
              rows={modifiedRows}
              setCurrentPage={setCurrentPage}
              pageCount={tableData?.rowData?.page?.totalPages}
              setPerPage={setPerPage}
              perPage={perPage}
              currentPage={currentPage}
            />
          </Card>
        )}
        {/* {openpopup && (
            <AssociationEdit
              openpopup={openpopup}
              setopenpopup={setopenpopup}
              title={"Association Edit"}
              selecteddata={selecteddata}
              tableDataAction={tableDataAction}
              passData={passData}
            />
          )} */}
        {openassociation && (
          <ClinicTeamassociationPopup
            openassociation={openassociation}
            setOpenassociation={setOpenassociation}
            passData={passData}
          />
        )}
      </DashboardLayout>
    );
  };

  

export default ClinicTeamAssociationList
