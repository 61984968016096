/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "brand-form",
  formField: {
    brandName: {
      name: "brandName",
      label: "Brand Name",
      type: "text",
      errorMsg: "Brand Name is required.",
    },
    brandDescrption: {
      name: "brandDescrption",
      label: "Brand Description",
      type: "text",
      errorMsg: "Brand Description is Required.",
    },
  },
};

export default form;
