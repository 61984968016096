import React from "react";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import swal from "sweetalert";
import axios from "../../../axiosinstance";

import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { APIs } from "Services/APIs";
import { Tooltip } from "@mui/material";
import { FaRegEdit } from "react-icons/fa";
import CategoryEdit from "app/Doctor/DoctorWorkspace/category/CategoryEdit";
import MDButton from "components/MDButton";
import { ROLE_CODE } from "privateComponents/codes";
// import LabCategory from "app/labTest/labTechnician/labCategory/labCategory";
import NewCategory from "app/Doctor/DoctorWorkspace/category";
import LabCategory from "app/lab/labCategory/labCategory";


function CategoryList({ sendDataToCategory }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [openpopupcategory, setopenpopupcategory] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getUserCategory } = useSelector((state) => state);
  const [popup, setPopup] = useState(false);

  const [popupd, setPopupd] = useState(false);
  const { getMMUDetails, getProjectDetails } = useSelector(state => state)
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  
  const linkTo =
   ROLE_CODE.labcode === auth.rolecode ||  ROLE_CODE.childlabcode === auth.rolecode
      ? `/app/lab/lab-category`
      : `/app/${auth.rolename?.toLowerCase()}/new-category`;

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "#039147",
    marginRight: "9px",
  };
  async function onUpdatedata(object) {
    setSelectedUser(object);
    setopenpopupcategory(true);
  }
  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Category.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const response = await axios.put(
            `${APIs.userCatgoryServiceApi}/delete`,
            item
          );
          if(auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode){
            dispatch(userCategoryAction({ mmucode:getProjectDetails?.details?.mmucreation?.code}));
          }else{
          dispatch(userCategoryAction({ profileid: auth.profileid}));
        }
          swal("Your Category record has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("There was an error deleting the category:", error);
          swal("Oops! There was an error deleting your Category record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your Category record is safe!");
      }
    });
  }

  function getData(list) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Category name", accessor: "category.name" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };

    list?.map((item) => {
      const newItem = {
        ...item,
        action: (
          <span>
            {ROLE_CODE?.doctorcode === auth?.rolecode ? (
              <Tooltip title="Edit">
                <Link to={{}}>
                  <FaRegEdit
                    style={iconStyleedit}
                    onClick={() => {
                      onUpdatedata(item);
                      setopenpopupcategory(true);
                    }}
                  />
                </Link>
              </Tooltip>
            ) : (
              <></>
            )}
            <Tooltip title="Delete">
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </Tooltip>
          </span>
        ),
        fcreateddate: new Date(item.createddate).toDateString(),
      };

      sendDataToCategory(list);
      dataObj.rows.push(newItem);
    });

    setTableDatas(dataObj);
    setLoading(false);
  }
  function handleAddButton() {
    if(ROLE_CODE?.labcode === auth?.rolecode && getProjectDetails.details.mmucreation === null){
      swal("You can not perform this action, because you are not assign with lab", {
        icon: 'error',
      })
      return;
    }

    if(auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode){
      setPopup(true);  
    }
    else{
      
    setPopupd(true);
    }
  }
  const handleClose = () => {
    setopenpopupcategory(false);
  };
  useEffect(() => {
    if(auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode && getProjectDetails?.details?.mmucreation?.code){
      dispatch(userCategoryAction({ mmucode:getProjectDetails?.details?.mmucreation?.code}));
    }else if(auth.profileid){
    dispatch(userCategoryAction({ profileid: auth.profileid}));
  }
  }, [dispatch, auth.profileid, refresh]);

  useEffect(() => {
    if(auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode && getProjectDetails?.details?.mmucreation?.code){
      dispatch(userCategoryAction({ mmucode:getProjectDetails?.details?.mmucreation?.code}));
    }
  }, [getProjectDetails?.details?.mmucreation]);
  
  useEffect(() => {
    if (getUserCategory?.category) {
      getData(getUserCategory.category);
    }
  }, [getUserCategory]);

  return (
    <>
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                  Category List
                  </MDTypography>
                </span>
                <Tooltip title="Add Category">
                  {/* <Link
                    style={{
                      alignSelf: "start",
                      color: "#344767",
                    }}
                    to={linkTo}
                  > */}
                  <MDButton color="info" onClick={handleAddButton}>ADD</MDButton>
                  {/* </Link> */}
                </Tooltip>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
        {openpopupcategory && (
          <CategoryEdit
            openpopupcategory={openpopupcategory}
            setopenpopupcategory={setopenpopupcategory}
            title={"Category Edit"}
            selectedUser={selectedUser}
            handleClose={handleClose}
            onUpdate={() => setRefresh((prev) => !prev)}
          />
        )}
           {popup && (
        <LabCategory
        popup={popup}
        setPopup={setPopup}
        onUpdate={() => setRefresh((prev) => !prev)}
       
        />
      )}
         
         {popupd && (
        <NewCategory
        popup={popupd}
        setPopup={setPopupd}
        onUpdate={() => setRefresh((prev) => !prev)}
       
        />
      )}
      </MDBox>
    </>
  );
}

export default CategoryList;
