import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import { createProjectTeam } from "../../../../app/MIC/micCreation/teamAssociation/create-team/service/ProjectTeam";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getUser } from "components/Resuable/UserMaster/service/User";
import { APIs } from "Services/APIs";
import { clinicappCode } from "static/labappCode";
import axios from "../../../../axiosinstance";
import { ROLE_CODE } from "privateComponents/codes";
import { variables } from "static/Variables";
import { tableAlreadyExistData } from "static/methods/tableAlreadyExistData";

export default function ClinicProjectTeamAssociationPopup(props) {
  const { openpopupclinicTeam, setopenpopupclinicTeam, handleClose, passData } =
    props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [designationList, setDesignationList] = useState([]);
  const [data, setData] = useState([]);
  const [project, setProjectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const { error, loading } = useSelector((state) => state.projectTeam);
  const { getProjectDetails } = useSelector((state) => state);
  const [idCounter, setIdCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    projectname: Yup.string().required("Clinic project is required"),
    departmentname: Yup.string().required("Department  is required"),
    designationname: Yup.string().required("Designation is required"),
    profilename: Yup.string().required("Profile name is required"),
  });

  const initialValues = {
    projectname: "",
    departmentname: "",
    designationname: "",
    profilename: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    if (await tableAlreadyExistData(data, values?.profilenameobject?.id))
      return;

    const newData = {
      uid: idCounter,

      projectcode: values?.projectname,
      projectname: values?.projectnamevalue,
      projectteams: values?.profilenameobject,
      mappingcode: values?.projecthirarichalcode,
      departmentname: values.departmentname,
      designationname: values.designationname,
      department: values.profilenameobject.departmentname,

      designation: values.profilenameobject.designationname,
      name: values.profilenameobject.lastname
        ? values.profilenameobject.firstname +
          " " +
          values.profilenameobject.lastname
        : values.profilenameobject.firstname,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    setDesignationList([]);
    setUserList([]);
    resetForm();
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "Clinic Project", accessor: "projectname" },
    { Header: "Department Name", accessor: "department" },
    { Header: "Designation Name", accessor: "designation" },
    { Header: "Profile Name", accessor: "name" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];

  async function finasubmit() {
    if (data.length === 0) {
      swal("Oops", "Please add data in the table first!", "error");
    } else {
      setIsLoading(true);
      var finalObjectlist = [];

      for (let i = 0; i < data.length; i++) {
        const response = {
          projectcode: data[i]?.projectcode,
          projectname: data[i]?.projectname,
          projectteams: {
            login: {
              ...data[i]?.projectteams,
              mappingcode: data[i]?.mappingcode,
              recstatus: variables.assign,
            },
          },
          mappingcode: data[i]?.mappingcode,
          createdbyname: auth.name,
          type: clinicappCode.mappingcode,
          recstatus: "OPEN",
          status: "ACTIVE",
          syncstatus: "SYNCED",
          createdby: auth.profileid,
        };

        finalObjectlist.push(response);
      }

      const response = await axios.post(
        `${APIs?.projectTeamService}/createlist`,
        finalObjectlist
      );
      if (!response?.error) {
        setIsLoading(false);
        handleClose();
        swal("Good job!", "Data has been created successfully!", {
          icon: "success",
        });
        passData();
      } else {
        setIsLoading(false);
        swal({
          icon: "error",
          title: "Error",
          text:
            response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
            "Something wents wrong.",
        });
        handleClose();
      }
    }
  }

  // async function getProfileDataByDeptCode(departmentcode) {
  //   let url = `${APIs?.loginServiceApi}/get?departmentcode=${departmentcode}&recstatus=APPROVED`
  //   await axios
  //     .get(url)
  //     .then((response) => {
  //       if (response?.data?.data?.listSize > 0) {
  //         setUserList(response?.data?.data?.dtoList);
  //       } else {
  //         swal(
  //           "",
  //           "No profile data found for the selected department!",
  //           "warning"
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       swal(
  //         "Error retrieving profile data.",
  //         `${error?.response?.data}.` || "Something wents wrong.",
  //         "warning"
  //       );
  //       console.log("error", e);
  //     });
  // }

  async function getprofileData(designationcode) {
    let url =
      auth?.rolecode === ROLE_CODE?.admincode
        ? `${APIs?.loginServiceApi}/get?designationcode=${designationcode}&recstatus=APPROVED`
        : `${APIs?.loginServiceApi}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&designationcode=${designationcode}&recstatus=APPROVED`;
    await axios
      .get(url)
      .then((response) => {
        if (response?.data?.data?.listSize > 0) {
          setUserList(response?.data?.data?.dtoList);
        } else {
          swal(
            "",
            "No profile data found for the selected designation!",
            "warning"
          );
        }
      })
      .catch((error) => {
        swal(
          "Error retrieving profile data.",
          `${error?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
        console.log("error", e);
      });
  }

  async function getDesignationData(code) {
    let url = `${APIs?.designationServiceApi}/get?deptcode=${code}`;
    try {
      const response = await axios.get(url);
      if (response?.data?.data?.listSize > 0) {
        setDesignationList(response?.data?.data?.dtoList);
        // getProfileDataByDeptCode(code);
      } else {
        swal(
          "",
          "No designation data found for the selected department!",
          "warning"
        );
      }
    } catch (error) {
      swal(
        "Error retrieving designation data.",
        `${error?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
      console.log("error", error);
      return;
    }
  }

  async function getData() {
    try {
      let url =
        auth?.rolecode === ROLE_CODE.admincode
          ? `${APIs.projectServiceApi}/get?type=${clinicappCode?.mappingcode}`
          : `${APIs.projectServiceApi}/getByPageV2?projectcode=${getProjectDetails?.details?.projecthirarichalcode}&type=${clinicappCode?.mappingcode}`;
      // Make the API call
      const response = await axios.get(url);

      setProjectList(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }

    let url = `${APIs?.deparmentServiceApi}/get`;

    try {
      const response = await axios.get(url);
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      swal(
        "Error retrieving department data.",
        `${error?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
      console.log("error", error);
    }
  }
  useEffect(() => {
    getData();
  }, [dispatch]);

  return (
    <Dialog
      open={openpopupclinicTeam}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <MDBox mx={2} mt={-3}>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={4}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={2}
                >
                  Clinic Project Team Association
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  color="white"
                  my={1}
                >
                  Enter your detail
                </MDTypography>
              </MDBox>
            </MDBox>
            <MDBox p={3}>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="projectname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Select Clinic Project
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="projectname"
                      id="projectname"
                      value={values.projectname}
                      onChange={(e) => {
                        const projectvalue = e.target.value;
                        const projectnamevalue =
                          project.find((obj) => obj?.code === projectvalue) ||
                          {};
                        setFieldValue(
                          "projectnamevalue",
                          projectnamevalue?.name
                        );
                        setFieldValue("projectname", projectvalue);
                        setFieldValue(
                          "projecthirarichalcode",
                          projectnamevalue?.projecthirarichalcode
                        );
                      }}
                    >
                      <option value=""></option>
                      {project?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="projectname"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="departmentname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Department
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="departmentname"
                      id="departmentname"
                      value={values.departmentname}
                      onChange={(e) => {
                        const departmentvalue = e.target.value;
                        setFieldValue("designationname", "");
                        setFieldValue("profilename", "");
                        setDesignationList([]);
                        setUserList([]);
                        if (departmentvalue) {
                          getDesignationData(e.target.value);
                          // getProfileDataByDeptCode(e.target.value);
                        }
                        setFieldValue("departmentname", departmentvalue);
                      }}
                    >
                      <option value=""></option>
                      {department?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="departmentname"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="designationname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Designation
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="designationname"
                      id="designationname"
                      value={values.designationname}
                      onChange={(e) => {
                        const designationvalue = e.target.value;
                        const departmentvalue = values?.departmentname;
                        setFieldValue("profilename", "");
                        setUserList([]);
                        // if (!designationvalue && departmentvalue) {
                        //   getProfileDataByDeptCode(departmentvalue);
                        // } else {
                        if (designationvalue) {
                          getprofileData(designationvalue);
                        }
                        // }
                        if (designationvalue) {
                          const data = {
                            departmentvalue: departmentvalue,
                            designationvalue: designationvalue,
                          };
                        }
                        setFieldValue("designationname", designationvalue);
                      }}
                    >
                      <option value=""></option>
                      {designationList?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="designationname"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="profilename"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Profile Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="profilename"
                      id="profilename"
                      value={values.profilename}
                      onChange={(e) => {
                        const profilenamevalue = e.target.value;
                        const profilenameobject =
                          userList?.find((obj) => obj?.id == e.target.value) ||
                          {};

                        setFieldValue("profilenameobject", profilenameobject);
                        setFieldValue("profilename", profilenamevalue);
                      }}
                    >
                      <option value=""></option>
                      {userList?.map((obj) => (
                        <option key={obj?.id} value={obj?.id}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    <ErrorMessage
                      name="profilename"
                      component="div"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    size="small"
                  >
                    Add
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>

            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "10px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <DataTable
                showTotalEntries={false}
                entriesPerPage={false}
                table={{ columns, rows: data }}
              />
            </Grid>
            <MDBox
              display="flex"
              justifyContent="flex-end"
              marginRight="10px"
              marginBottom="8px"
            >
              <MDButton
                variant="gradient"
                color="info"
                onClick={() => finasubmit()}
              >
                {isLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "submit"
                )}
              </MDButton>
            </MDBox>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}



