export const ROLE_CODE = 
    {
        admincode:"AD001",
        patientcode: "PA001",
        doctorcode :"DO001",
        nursecode:"RE001",
        pharmacycode:"PH257",
        agentcode:"AG2773",
        labcode: "LB001",
        miccode: "MI8236",
        collectiveagentcode: "CA001",
        childlabcode:"LBC001",
        cliniccode:"CL139",
        childcliniccode:"CLC139"

    }