/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import { useDispatch } from "react-redux"

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import routesAfterlogin from "routesAfterlogin";
import routesAdmin from "routesAdmin";
import routesAgent from "routesAgent";
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";
import routesMic from "routesmic";
import routesLab from "routesLab";
import routesCollectiveAgent from "routesCollectiveAgent";
import { ROLE_CODE } from "privateComponents/codes";
import routesChildlab from "routesChildlab";
import routesClinic from "routesClinic";
function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  var [renderRoutes, setRenderRoutes] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname?.split("/")?.slice(1)[0];
  const items = pathname?.split("/")?.slice(1);
  const itemParentName = items[1];
  const itemName = items[items.length - 1];
  const reduxDispatch = useDispatch()

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }




    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch,transparentSidenav, whiteSidenav, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    return collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

   
  };
  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;

      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }) =>
              openNestedCollapse === key && currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem color={color} name={name} active={key === itemName} />
          </Link>
        ) : (
          
          <NavLink to={route} onClick={() => key === "logout" && reduxDispatch({ type: "LOGOUT_FULFILLED" })} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem color={color} name={name} active={route === pathname} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "DO001") {
    renderRoutes = routesAfterlogin.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;

        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
          // localStorage.setItem('userfullName', JSON.stringify({userfullName: firstname2+" "+lastname2}))
          name = firstname2 + " " + lastname2




        }

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            returnValue = (
              <NavLink to={route} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }

        return returnValue;
      }
    );
  }

 
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "RE001") {
    renderRoutes = routesReceptionist.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            
            returnValue = (
              <NavLink to={route} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }

        return returnValue;
      }
    );
  }
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "AD001") {
    renderRoutes = routesAdmin.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            
            returnValue = (
              <NavLink to={route} key={key}>
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  noCollapse={noCollapse}
                  active={key === collapseName}
                >
                  {collapse ? renderCollapse(collapse) : null}
                </SidenavCollapse>
              </NavLink>
            );
          } else {
            returnValue = (
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            );
          }
        } else if (type === "title") {
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }

        return returnValue;
      }
    );
  }
 
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "AG2773") {
    renderRoutes = routesAgent.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  }
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "MI8236") {
    renderRoutes = routesMic.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;

        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  }
else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === ROLE_CODE.labcode) {
    renderRoutes = routesLab.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  } 
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === ROLE_CODE.childlabcode) {
    renderRoutes = routesChildlab.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  }
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === ROLE_CODE.cliniccode) {
    renderRoutes = routesClinic.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  } 
  else if (JSON.parse(localStorage.getItem("login-auth"))?.rolecode === "CA001") {
    renderRoutes = routesCollectiveAgent.map(
      ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
        let returnValue;
        const isActive = route === pathname;
        if (key === "brooklyn-alice") {
          var oldName = localStorage.getItem("login-auth")
            ? JSON.parse(localStorage.getItem("login-auth"))?.name : ""

          var firstName = oldName?.split(' ')?.slice(0, -1).join(' ');
          var lastName = oldName?.split(' ')?.slice(-1).join(' ');
          var firstname = firstName;
          var lastname = lastName;
          var firstname2 = firstname?.charAt(0).toUpperCase() + firstname?.slice(1);
          var lastname2 = lastname?.charAt(0).toUpperCase() + lastname?.slice(1);
       
          name = firstname2 + " " + lastname2

        }

        // Check if the route is of type "collapse"
        if (type === "collapse") {
          
          // Render a collapse item
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
            >
              {/* Render nested collapse items if available */}
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        } 
        // Check if the route is of type "title"
        else if (type === "title") {
          // Render a title item
          returnValue = (
            <MDTypography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
          );
        } 
        // Check if the route is of type "divider"
        else if (type === "divider") {
          // Render a divider item
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        // If none of the above conditions match, render a regular item
        else {
          returnValue = (
            <NavLink to={route} key={key}>
              {/* Render a regular item */}
              <SidenavItem 
                name={name} 
                active={isActive} 
                icon={icon}
                // Add other props as needed
              />
            </NavLink>
          );
        }

        return returnValue;
      }
    );
  }
  // else if (JSON.parse(localStorage.getItem("login-auth")).rolecode === "CA001") {
  //   renderRoutes = routesCollectiveAgent.map(
  //     ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
  //       let returnValue;
  //       if (key === "brooklyn-alice") {
  //         var oldName = localStorage.getItem("login-auth")
  //           ? JSON.parse(localStorage.getItem("login-auth")).name : ""

  //         var firstName = oldName.split(' ').slice(0, -1).join(' ');
  //         var lastName = oldName.split(' ').slice(-1).join(' ');
  //         var firstname = firstName;
  //         var lastname = lastName;
  //         var firstname2 = firstname.charAt(0).toUpperCase() + firstname.slice(1);
  //         var lastname2 = lastname.charAt(0).toUpperCase() + lastname.slice(1);
       
  //         name = firstname2 + " " + lastname2

  //       }

  //       if (type === "collapse") {
  //         if (href) {
  //           returnValue = (
  //             <Link
  //               href={href}
  //               key={key}
  //               target="_blank"
  //               rel="noreferrer"
  //               sx={{ textDecoration: "none" }}
  //             >
  //               <SidenavCollapse
  //                 name={name}
  //                 icon={icon}
  //                 active={key === collapseName}
  //                 noCollapse={noCollapse}
  //               />
  //             </Link>
  //           );
  //         } else if (noCollapse && route) {
            
  //           returnValue = (
  //             <NavLink to={route} key={key}>
  //               <SidenavCollapse
  //                 name={name}
  //                 icon={icon}
  //                 noCollapse={noCollapse}
  //                 active={key === collapseName}
  //               >
  //                 {collapse ? renderCollapse(collapse) : null}
  //               </SidenavCollapse>
  //             </NavLink>
  //           );
  //         } else {
  //           returnValue = (
  //             <SidenavCollapse
  //               key={key}
  //               name={name}
  //               icon={icon}
  //               active={key === collapseName}
  //               open={openCollapse === key}
  //               onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
  //             >
  //               {collapse ? renderCollapse(collapse) : null}
  //             </SidenavCollapse>
  //           );
  //         }
  //       } else if (type === "title") {
  //         returnValue = (
  //           <MDTypography
  //             key={key}
  //             color={textColor}
  //             display="block"
  //             variant="caption"
  //             fontWeight="bold"
  //             textTransform="uppercase"
  //             pl={3}
  //             mt={2}
  //             mb={1}
  //             ml={1}
  //           >
  //             {title}
  //           </MDTypography>
  //         );
  //       } else if (type === "divider") {
  //         returnValue = (
  //           <Divider
  //             key={key}
  //             light={
  //               (!darkMode && !whiteSidenav && !transparentSidenav) ||
  //               (darkMode && !transparentSidenav && whiteSidenav)
  //             }
  //           />
  //         );
  //       }

  //       return returnValue;
  //     }
  //   );
  // }


  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <a href="https://medharva.com">
        
        <MDBox display="flex" alignItems="center" >
          {brand && <MDBox component="img" src={brand} alt="Brand" width="2rem" bgColor="light" borderRadius="lg"/>}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
        
        </a>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;


