import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";




// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import { ROLE_CODE } from "privateComponents/codes";


// Overview page components
import Header from "layouts/pages/profile/components/Header";

import lottie from "lottie-web";
import { Card } from "reactstrap";

import ProfileCompletion from "components/ProfileCompletion/ProfileCompletion";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import axios from "../../../axiosinstance";
import { useDispatch } from "react-redux";



function ProfileInformation(props) {

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
 const dispatch = useDispatch();
  const container = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require('../../../components/data/ambulance.json'),
    });
  }, []);

  var oldName = auth?.name;

  var firstName = oldName && oldName?.split(' ').slice(0, -1).join(' ');
  var lastName = oldName && oldName?.split(' ').slice(-1).join(' ');
  var firstname = firstName;
  var lastname = lastName;
  var firstname2 = firstname.charAt(0).toUpperCase() + firstname.slice(1);
  var lastname2 = lastname.charAt(0).toUpperCase() + lastname.slice(1);
  const [clinicname, setClinicName] = useState("");
const getClinicname =async (profileid)=>{
 try {
       
     const response = await axios.get(`${APIs.mmuteamAssociation}/get?profileid=${profileid}`);
  
          // Update the state by filtering out the deleted item
          setClinicName(response?.data?.data?.dtoList?.[0]?.mmuname);
  
        
        } catch (error) {
          console.error("Somthing Went wrong");
         
        }
}
  useEffect(async () => {
     
      if (auth?.rolecode === ROLE_CODE.cliniccode) {
        getClinicname(auth?.profileid)
      }
    }, [dispatch]);
  return (
    <DashboardLayout>

      <MDBox mb={2} mt={-7} />
      <Header>
        <MDBox mt={5} mb={2}>
          <Grid container spacing={1}>
 
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"

                info={{
                  name: firstname2 + " " + lastname2,
                  ...(auth?.rolecode === ROLE_CODE.cliniccode && { clinicName: clinicname }),
                  Role: auth?.rolename,

                  mobile: auth?.mobileno,
                  email: auth?.emailid,
                  location: "INDIA",
                }}
                social={[

                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />

            </Grid>
            <Grid item xs={12} xl={6} md={6} sx={{ display: "flex", alignItems: "start", justifyContent: "center" }}  >
              {auth.rolecode === ROLE_CODE.doctorcode ? (

                <ProfileCompletion
                  qualificationList={props?.qualificationList}
                  categoryList={props?.categoryList}
                  addressList={props?.addressList}
                  visitList={props?.visitList}
                  title={"Profile Status"}
                />
              ) : (<></>
              )}


            </Grid>
          </Grid>
        </MDBox>


      </Header>
    </DashboardLayout>
  );
}

export default ProfileInformation;