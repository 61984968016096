import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{ vaccineName,fromAge,toAge,dependency,nature,applicable,brand},
} = checkout;

const validations = [
  Yup.object().shape({
    [vaccineName.name]: Yup.string().required(vaccineName.errorMsg),
    // [fromAge.name]: Yup.number()
    // .required(fromAge.errorMsg)
    // .typeError("From Age must be a number")
    // .lessThan(
    //   Yup.ref(toAge.name),
    //   `${fromAge.label} must be less than ${toAge.label}`
    // ),
    [fromAge.name]: Yup.number()
  .required(fromAge.errorMsg)
  .typeError("From Age must be a number")
  .test(
    "less-than-or-equal",
    `${fromAge.label} must be less than or equal to ${toAge.label}`,
    function (value) {
      const toAgeValue = this.resolve(Yup.ref(toAge.name));
      return value <= toAgeValue;
    }
  ),
  [toAge.name]: Yup.number()
    .required(toAge.errorMsg)
    .typeError("To Age must be a number"),


  [applicable.name]: Yup.string().required(applicable.errorMsg),
  [nature.name]: Yup.string().required(nature.errorMsg),
  [brand.name]: Yup.array().min(1, brand.errorMsg).required(brand.errorMsg),

}),
];

export default validations;
