import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";

import { homeRoutes } from "./routes/homeRoutes";
import { doctorRoutes } from "routes/doctorRoutes";
import { patientRoutes } from "routes/patientRoutes";
import PrivateDoctor from "privateComponents/PrivateDoctor";
import Login from "app/login/Login";
import { ROLE_CODE } from "privateComponents/codes";
import PrivatePatient from "privateComponents/PrivatePatient";
import { nurseRoutes } from "routes/nurseRoutes";
import PrivateNurse from "privateComponents/PrivateNurse";
import { agentRoutes } from "routes/agentRoutes";
import { adminRoutes } from "routes/adminRoutes";
import { micRoutes } from "routes/micRoutes";

import { labRoutes } from "routes/labRoutes";
import PrivateLab from "privateComponents/PrivateLab";
import Doctorsignup from "app/Doctor/DoctorWorkspace/doctorsignup/Doctorsignup";
import { collectiveagentRoutes } from "routes/collectiveagentRoutes";
import { clinicRoutes } from "routes/clinicRoutes";
import PrivateClinic from "privateComponents/PrivateClinic";


export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const [router, setRouter] = useState(routes);
  // console.log(routes, "Routes 37");

  // const settingusername = () => {
  //   if (routes !== null) {
  //     routes
  //       .filter((item) => item.key === "brooklyn-alice")
  //       .map((item) => {
  //         item.name = localStorage.getItem("profile-Name");
  //       });
  //     setRouter(routes);
  //   }
  // };

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector(({ AuthReducer }) => AuthReducer);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [userLocation, setUserLocation] = useState(null);

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };
  useEffect(() => {
    handleGetLocation();
  }, []);

  useEffect(() => {
    if (userLocation) {
      // Store user location in local storage
      localStorage.setItem("userLocation", JSON.stringify(userLocation));
    }
  }, [userLocation]);

  const isInitialRender = useRef(true);
  const doctorid = JSON.parse(
    localStorage.getItem("doctor-profileid")
  )?.profileid;

  const navigateOnPageRole = () => {
    const redirectUrl = localStorage.getItem("redirectUrl");

   if (auth && auth?.rolecode === ROLE_CODE.doctorcode) {
      navigate("/app/doctor/profile");
    } else if (
      auth &&
      auth?.rolecode === ROLE_CODE.patientcode &&
      auth.logincount === "1"
    ) {
      navigate("/app/patient/patient-information");
    } else if (auth && auth?.rolecode === ROLE_CODE.patientcode) {
      navigate("/app/home/find-doctors");
    } else if (auth && auth?.rolecode === ROLE_CODE.nursecode) {
      navigate("/app/home/find-doctors");
    } else if (auth && auth?.rolecode === ROLE_CODE.agentcode) {
      navigate("/app/agent/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.miccode) {
      navigate("/app/mic/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.pharmacycode) {
      navigate("/app/pharmacy/profile");
    } else if (auth && auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      navigate("/app/lab/profile");
    }else if (auth && auth?.rolecode === ROLE_CODE.collectiveagentcode) {
      navigate("/app/collectiveagent/profile");
    }
    else if (auth && auth?.rolecode === ROLE_CODE.cliniccode) {
      navigate("/app/clinic/profile");
    }else if (auth && auth?.rolecode === ROLE_CODE.admincode) {
      navigate("/app/admin/profile");
    } else if (redirectUrl) {
      navigate(redirectUrl);
      localStorage.removeItem("redirectUrl");
    } else {
      navigate("/app/home/find-doctors");
    }
  };

  useEffect(() => {
    // Check if it's the initial render
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    const dynamicNavigation = localStorage.getItem("dynamic-navigation");
    const navigationLink = dynamicNavigation || "/app/finddoctor/Speciality";
    navigateOnPageRole();
  }, [auth?.rolecode]);

  useEffect(() => {
    const redirectUrl = localStorage.getItem("redirectUrl");
    if (redirectUrl) {
      navigate(redirectUrl);
      return;
    }
  }, [auth]);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // const getRoutes = (allRoutes) =>
  //   allRoutes.map((route) => {
  //     if (route.collapse) {
  //       return getRoutes(route.collapse);
  //     }

  //     if (route.route) {
  //       return (
  //         <Route
  //           basename="/app"
  //           exact
  //           path={route.route}
  //           element={route.component}
  //           key={route.key}
  //         />
  //       );
  //     }

  //     return null;
  //   });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );
  // console.log(homeRoutes, "routes 249")

  // new Implementation

  const handleRoutes = (homeRout) => {
    return homeRout.map((item, index) => (
      <Route
        basename="/app/home"
        key={index}
        path={item.path}
        element={item.components}
      />
    ));
  };

  // return
  // direction === "rtl" ? (
  //   <CacheProvider value={rtlCache}>
  //     <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //       <CssBaseline />
  //       {layout === "dashboard" && (
  //         <>
  //           <Sidenav
  //             color={sidenavColor}
  //             brand={
  //               (transparentSidenav && !darkMode) || whiteSidenav
  //                 ? brandDark
  //                 : brandWhite
  //             }
  //             brandName="Material Dashboard PRO"
  //             routes={routes}
  //             onMouseEnter={handleOnMouseEnter}
  //             onMouseLeave={handleOnMouseLeave}
  //           />
  //           <Configurator />
  //           {configsButton}
  //         </>
  //       )}
  //       {layout === "vr" && <Configurator />}
  //       <Routes>
  //         {/* Home page routing */}
  //         <Route>
  //           {handleRoutes(homeRoutes)}
  //         </Route>
  //         <Route
  //           basename="/app"
  //           path="*"
  //           element={<Navigate to="/app/login" />}
  //         />
  //       </Routes>
  //     </ThemeProvider>
  //   </CacheProvider>
  // ) :
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {/* {layout === "dashboard" && (
        <>
          {(auth?.rolecode === "AD001" || auth?.rolecode === "AG2773") && (
            <Sidenav
              color={sidenavColor}
              brand={
                (transparentSidenav && !darkMode) || whiteSidenav
                  ? brandDark
                  : brandWhite
              }
              brandName="Medharva"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
          )}

          <Configurator />
          {configsButton}
        </>
      )} */}
      {/* {layout === "vr" && <Configurator />} */}
      {/* <Configurator /> */}
      {/* {configsButton} */}
      <Routes>
        <Route path="app/login" element={<Login />} />
        <Route path="app/doctor-signup" element={<Doctorsignup />} />

        {/* Home page routing */}
        <Route>{handleRoutes(homeRoutes)}</Route>

        {/* Doctor Page routing  */}
        <Route element={<PrivateDoctor />}>{handleRoutes(doctorRoutes)}</Route>

        {/* Patient page routing */}
        <Route element={<PrivatePatient />}>
          {handleRoutes(patientRoutes)}
        </Route>

        {/* Nurse page routing */}
        <Route element={<PrivateNurse />}>{handleRoutes(nurseRoutes)}</Route>

        {/* Agent page routing */}
        <Route>{handleRoutes(agentRoutes)}</Route>

        {/* MIC app routing */}
        <Route>{handleRoutes(micRoutes)}</Route>
    
        <Route element={<PrivateClinic />}>
          {handleRoutes(clinicRoutes)}
        </Route>

        {/* ADMIN PAGE ROUTE=================== */}
        <Route>{handleRoutes(adminRoutes)}</Route>
        <Route element={<PrivateLab />}>
          {handleRoutes(labRoutes)}
        </Route>
        
        <Route>{handleRoutes(collectiveagentRoutes)}</Route>
        <Route
          basename="/app"
          path="*"
          element={<Navigate to="/app/home/find-doctors" />}
        />
      </Routes>
    </ThemeProvider>
  );

  // return direction === "rtl" ? (
  //   <CacheProvider value={rtlCache}>
  //     <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
  //       <CssBaseline />
  //       {layout === "dashboard" && (
  //         <>
  //           <Sidenav
  //             color={sidenavColor}
  //             brand={
  //               (transparentSidenav && !darkMode) || whiteSidenav
  //                 ? brandDark
  //                 : brandWhite
  //             }
  //             brandName="Material Dashboard PRO"
  //             routes={routes}
  //             onMouseEnter={handleOnMouseEnter}
  //             onMouseLeave={handleOnMouseLeave}
  //           />
  //           <Configurator />
  //           {configsButton}
  //         </>
  //       )}
  //       {layout === "vr" && <Configurator />}
  //       <Routes>

  //         {getRoutes(routes)}
  //         <Route
  //           basename="/app"
  //           path="*"
  //           element={<Navigate to="/app/pages/profile/profile-overview" />}
  //         />
  //       </Routes>
  //     </ThemeProvider>
  //   </CacheProvider>
  // ) : (
  //   <ThemeProvider theme={darkMode ? themeDark : theme}>
  //     <CssBaseline />
  //     {layout === "dashboard" && (
  //       <>
  //         {(auth?.rolecode === "AD001" || auth?.rolecode === "AG2773") && (
  //           <Sidenav
  //             color={sidenavColor}
  //             brand={
  //               (transparentSidenav && !darkMode) || whiteSidenav
  //                 ? brandDark
  //                 : brandWhite
  //             }
  //             brandName="Medharva"
  //             routes={routes}
  //             onMouseEnter={handleOnMouseEnter}
  //             onMouseLeave={handleOnMouseLeave}
  //           />
  //         )}

  //         <Configurator />
  //         {configsButton}
  //       </>
  //     )}
  //     {layout === "vr" && <Configurator />}
  //     <Routes>
  //       {getRoutes(routes)}
  //       <Route
  //         basename="/app"
  //         path="*"
  //         element={<Navigate to="/app/pages/profile/profile-overview" />}
  //       />
  //     </Routes>
  //   </ThemeProvider>
  // );
}
