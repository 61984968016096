import React, { useEffect, useState } from "react";

import MDBox from "components/MDBox";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import { Autocomplete, Grid, useMediaQuery } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useSelector, useDispatch } from "react-redux";

import { BarLoader } from "react-spinners";

import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { ROLE_CODE } from "privateComponents/codes";
import CreatePatient from "./CreatePatient";
import CreateDoctor from "./CreateDoctor";
import CreateAgent from "./CreateAgent";
import CreateNurseadmin from "./CreateNurseadmin";
import CreateLabAdmin from "./CreateLabAdmin";
import CreateMic from "./CreateMic";
import CreateClinic from "./CreateClinic";

const DynamicComponent = () => {
  const [selectedOption, setSelectedOption] = useState(ROLE_CODE.labcode);
  const [roledata, setRoleData] = useState({});
  const dispatch = useDispatch();
  const { role, loading } = useSelector((state) => state.role);
  const [color] = useState("#4CAF50");
  const isSmallScreen = useMediaQuery("(max-width:575px)");

  const handleDropdownChange = (newValue) => {
    setRoleData(newValue);
  };

  const defaultPropsRole = {
    options: !role ? [{ name: "Loading...", id: 0 }] : role,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const renderSelectedComponent = () => {
    switch (selectedOption) {
      case ROLE_CODE.patientcode:
        return <CreatePatient roledata={roledata} />;
      case ROLE_CODE.doctorcode:
        return <CreateDoctor roledata={roledata} />;
      case ROLE_CODE.agentcode:
        return <CreateAgent roledata={roledata} />;
      case ROLE_CODE.nursecode:
        return <CreateNurseadmin roledata={roledata} />;
      case ROLE_CODE.labcode:
        return <CreateLabAdmin roledata={roledata} />;
      case ROLE_CODE.miccode:
        return <CreateMic roledata={roledata} />;
        case ROLE_CODE.cliniccode:
          return <CreateClinic roledata={roledata} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    dispatch(getRole());
  }, [dispatch]);

  const renderAutocomplete = () => (
    <Autocomplete
      {...defaultPropsRole}
      onChange={(event, newValue) => {
        handleDropdownChange(newValue);
        setSelectedOption(newValue?.rolecode);
        if (newValue === null) {
          setSelectedOption(ROLE_CODE.patientcode);
        }
      }}
      renderInput={(params) => (
        <MDInput {...params} variant="standard" label="Role" />
      )}
    />
  );

  return (
    <DashboardLayout>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 12 }}
      >
        <Grid item xs={12} lg={8}>
          <Card sx={{ height: "100%" }}>
            <MDBox mx={2} mt={-3}>
              <MDBox
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={2}
                mb={-2}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="small"
                  color="white"
                  mt={1}
                >
                  New User
                </MDTypography>
              </MDBox>
            </MDBox>
            {loading ? (
              // Display a loader while data is being fetched
              <BarLoader
                style={{ marginTop: "35px" }}
                color={color}
                loading={loading}
                aria-label="Loading Spinner"
              />
            ) : (
              <>
                {isSmallScreen ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Grid item xs={12} sm={6} mt={5} ml={3} mr={3}>
                      {renderAutocomplete()}
                    </Grid>
                  </div>
                ) : (
                  <Grid container spacing={2} mt={5} ml={1}>
                    <Grid item xs={12} sm={5.6}>
                      {renderAutocomplete()}
                    </Grid>
                  </Grid>
                )}
                {renderSelectedComponent()}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default DynamicComponent;
