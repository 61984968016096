import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import axios from "axiosinstance";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import MDButton from "components/MDButton";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { APIs } from "Services/APIs";

const Brandedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedBrand, openpopup, setopenpopup,passData  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading,setLoading] = useState(false)

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Brand Name is required"),
    description: Yup.string().required("Description is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedBrand?.name || "",
      description: selectedBrand?.description || "",
    },
    validationSchema,
    onSubmit: async(values) => {
   setLoading(true)
      const updatedBrand = {
        ...selectedBrand,
        name: values.name,
        description: values.description,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
  
      };

      const response = await axios.put(
          `${APIs.catgoryServiceApi}/update`,
          updatedBrand
        );
  
  if (!response.error) {
   
  
    swal("Good job!", "Data has been updated successfully!", {
      icon: "success",
    });
    handleClose();
    setLoading(false);
    passData()
  } else {
    swal({
      icon: "error",
      title: "Error",
      text:
        response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
        "Something wents wrong.",
    });
    setLoading(false);
    // handleClose()
  }

    },
  });

  useEffect(() => {
    // Update the form values when selectedBrand changes
    formik.setValues({
      name: selectedBrand?.name || "",
      description: selectedBrand?.description || "",
    });
  }, [selectedBrand]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>Brand Name<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=<span>Brand Description<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={
                formik.touched.description && formik.errors.description ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.description}
                  </p>
                ) : null
              }
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} size="small" color="light">
            Cancel
          </MDButton>

          <MDButton type="submit" variant="gradient" size="small" color="info">
            {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Brandedit;
