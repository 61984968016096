import React, { useEffect, useState } from "react";
import { Button, Grid, Radio, useMediaQuery } from "@mui/material";
import "../BookLabTest/style.css";
import Navigatbtn from "./Navigatbtn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import PatientAddressPopup from "./PatientAddresspopup/componets/PatientAddressPopup";
import { MdDelete } from "react-icons/md";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { APIs } from "Services/APIs";
import { labAddCartAction } from "redux/Actions/labAddCart";
import { FaRegEdit } from "react-icons/fa";
import PatientAddressEdit from "./PatientAddresspopup/componets/PatientAddressEdit";

const SelectAddres = () => {
  const navigate = useNavigate();
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
  const [paymentmethod, setPaymentmethod] = useState(
    localStorage.getItem("paymentmethod")
      ? localStorage.getItem("paymentmethod")
      : ""
  );
  const [addresslist, setAddresslist] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addressloading, setAddressLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();
  const options = ["Pay on Counter", "Online"];
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);
  const [openpopup, setopenpopup] = useState(false);
  const dispatch = useDispatch();
  const { getAddLabCart } = useSelector((state) => state);
  const [openpopupedit, setOpenPopupedit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const newSelectedDate = new Date();
  const formattedDate = newSelectedDate.toLocaleDateString("en-CA");
  console.log("getAddLabCartgetAddLabCart",getAddLabCart)

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getAddress(profileId) {
    setAddressLoading(true);
    await axios
      .get(`${APIs.addressServiceApi}/get?profileId=${profileId}`)
      .then((response) => {
        setAddresslist(response.data.data.dtoList);
      setAddressLoading(false);
      })

      .catch((e) => {
        setAddressLoading(false);
        console.log("error", e);
      });
  }

  useEffect(() => {
    getAddress(auth.profileid);
    // const storedAddressIndex = localStorage.getItem("selectedAddressIndex");
    // if (storedAddressIndex !== null) {
    //   setSelectedAddressIndex(Number(storedAddressIndex));
    // }
  }, [auth.profileid]);



  // useEffect(() => {
  //   const storedAddressIndex = localStorage.getItem("selectedAddressIndex");
  //   if (!storedAddressIndex) {
  //   const defaultIndex = addresslist?.findIndex(
  //     (card) => card?.addresstype === "Default"
  //   );
  //   if (defaultIndex !== -1) {
  //     setSelectedAddressIndex(defaultIndex);
  //     localStorage.setItem("selectedAddressIndex", defaultIndex); 
  //   }
  // }
  
  // }, [addresslist]);


  const handleNextButtonClick = () => {
    setLoading(true);
    if (
      getAddLabCart?.address !== null &&
      getAddLabCart?.paymentMethod !== "" &&
      getAddLabCart?.paymentMethod !== null &&
      getAddLabCart?.paymentMethod !== undefined
    ) {
      // const selectedAddress = addresslist[selectedAddressIndex];
      // const labs = JSON.parse(localStorage.getItem("selectedLabs"));
      const labs = getAddLabCart?.labs?.filter(
        (item) => item.pharmacy && item.timing && item.price !== null
      );
      // const patient = JSON.parse(localStorage.getItem("labformData"))
      //   ?.family[0];
      // const slot = JSON.parse(localStorage.getItem("selectedLabs"))?.timing;
      let labBookings = [];

      labs?.map((item) => {
        const getData = {
          classname: "Booking",
          pricelist: item?.price,
          createdby: auth?.profileid,
          mmu: item?.pharmacy,
          id: null,
          profileid: auth?.profileid,
          paymentmethod: getAddLabCart?.paymentMethod,
          // patient: patient,
          patient: getAddLabCart?.selectedPerson,
          patientaddress: getAddLabCart?.address,
          type: "labtest",
          slot: item?.timing,
          recstatus: "SCHEDULED",
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        labBookings.push(getData);
      });
       axios
        .post(`${APIs.bookingServiceApi}/createList`, labBookings)
        .then(() => {
          swal("Success!", "Your lab test is booked successfully.", "success");
          dispatch(labAddCartAction({ clear:true }));
          navigate(`/app/${auth?.rolename?.toLowerCase()}/my-labtests`);
          // localStorage.removeItem("labtest-code");
          // localStorage.removeItem("selectedLabTests");
          // localStorage.removeItem("selectedLocation");
          // localStorage.removeItem("labformData");
          // localStorage.removeItem("selectedTiming");
          // localStorage.removeItem("selectedPharmacy");
          // localStorage.removeItem("selectedDate");
          // localStorage.removeItem("selectedPerson");
          // localStorage.removeItem("dayNumber");
          // localStorage.removeItem("paymentmethod");
          // localStorage.removeItem("selectedAddressIndex");
          // localStorage.removeItem("selectedLabs");
          setLoading(false);
        })
        .catch((error) => {
          swal(
            "Oops!",
            error?.response?.data?.aceErrors[0]?.errorMessage ,
            "warning"
          );
          console.log(error);
          setLoading(false);
        });
    } else {
      swal(
        "Oops!",
        "Please select an address or a payment method before clicking 'Next'.",
        "error"
      );
      setLoading(false);
    }
  };
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "#039743",
    marginRight: "9px",
  };
  const isMobileOrTablet = useMediaQuery("(max-width:825px)");
  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this address.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        axios
          .put(`${APIs.addressServiceApi}/delete`, item)
          .then((response) => {
            console.log(response);
            getAddress(auth.profileid);
          });
        swal("Your Address record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Address record is safe!");
      }
    });
  }
  async function onUpdatedata(object) {
    setSelectedUser(object);
    setOpenPopupedit(true);
  }
  const handleClose = () => {
    setOpenPopupedit(false);
  };

  return (
    <div>
      <Grid mt={isMidScreen ? 23 : 18} className="select-address-a">
        <Grid className="select-address-b">
          {!isMobileOrTablet ? (
            <Grid className="select-address-c ">
              <span className="select-address-d">
                <span className="select-address-d">
                  Select the sample pick-up address
                </span>
              </span>
            </Grid>
          ) : (
            <Grid className="select-address-c-2">
              <span className="select-address-d-2">Selected Address</span>
            </Grid>
          )}
          <Grid
            container
            spacing={3}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Grid item xs={12} sm={6}>
              <select
                id="payment"
                name="payment"
                value={getAddLabCart?.paymentMethod} 
                onChange={(e) => {
                  dispatch(labAddCartAction({...getAddLabCart,paymentMethod:e.target.value}))
                  // localStorage.setItem("paymentmethod", e.target.value);
                  // setPaymentmethod(e.target.value);
                }}
                // onBlur={formik.handleBlur}

                style={{
                  padding: "10px",
                  marginBottom: "10px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  fontSize: "14px",
                }}
              >
                <option value="" label="Select Payment Method" />
                {options?.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={12} sm={2}></Grid>

            <Grid item xs={12} sm={1.8}>
              <MDButton
                color="info"
                size="small"
                onClick={() => setopenpopup(true)}
              >
                Create Address
              </MDButton>
            </Grid>
          </Grid>
         
          <Grid className="select-address-e">
            {addressloading ? (
              <Grid mt={3} className="select-address-f">
                <div className="select-address-g">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <MoonLoader color="green" size={30} />
                  </div>
                </div>
              </Grid>
            ) : addresslist?.filter(
                (card) =>
                  card?.firstline &&
                  card?.secondline &&
                  card?.city &&
                  card?.state &&
                  card?.postcode &&
                  card?.country
              ).length === 0 ? (
              <Grid mt={3} className="select-address-f">
                <div className="select-address-g">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Address not found!
                  </div>
                </div>
              </Grid>
            ) : (
              addresslist?.map((card, index) => {
                if (
                  !card?.firstline ||
                  !card?.secondline ||
                  !card?.city ||
                  !card?.state ||
                  !card?.postcode ||
                  !card?.country
                ) {
                  return null;
                }

                return (
                  <div key={index}>
                    <Grid mt={3} className="select-address-f">
                      <div className="select-address-g">
                        <Grid className="select-address-h">
                          <Grid className="select-address-i">
                          Address : <span className="select-address-ii">{card.addresstype}</span>
                          </Grid>
                          <Grid>
                            <Radio
                              color="success"
                              checked={card.id === getAddLabCart?.address?.id}
                              onChange={() =>
                                
                                dispatch(labAddCartAction(({...getAddLabCart, address:card})))
                                //  handleRadioChange(card)
                                }
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  width: {
                                    xs: 15,
                                    sm: 25,
                                    md: 30,
                                    lg: 30,
                                    xl: 30,
                                  },
                                  height: {
                                    xs: 15,
                                    sm: 25,
                                    md: 30,
                                    lg: 30,
                                    xl: 30,
                                  },
                                  color: "green",
                                  marginBottom: "15px",
                                },
                              }}
                            />
                            <Link to={{}}>
                              <FaRegEdit
                                style={iconStyleedit}
                                onClick={() => {
                                  onUpdatedata(card);
                                  setOpenPopupedit(true);
                                }}
                              />
                            </Link>
                            <Link to={{}}>
                              <MdDelete
                                style={iconStyle}
                                onClick={() => {
                                  onDelete(card);
                                }}
                              />
                            </Link>
                          </Grid>
                        </Grid>
                        <Grid className="select-address-j">
                          <Grid
                            className="select-address-k"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {card?.firstline}, {card?.secondline}, {card?.city}
                          </Grid>
                          <Grid
                            className="select-address-l"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {card?.state}, {card?.postcode}, {card?.country}
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </div>
                );
              })
            )}
          </Grid>
          <Grid
            mb={isMidScreen ? 10 : 5}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() =>
                    navigate(
                      `/app/${auth?.rolename?.toLowerCase()}/select-person`
                    )
                  }
                >
                  Previous
                </button>
              </div>
              <div>
                <button
                  className="next"
                  onClick={() => handleNextButtonClick()}
                >
                  {loading ? (
                    <MoonLoader color="#f2fefa" size={24} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <PatientAddressPopup
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        getAddress={getAddress}
      />

      {openpopupedit && (
        <PatientAddressEdit
          openpopup={openpopupedit}
          setopenpopup={setOpenPopupedit}
          title={"Address Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          getData={getAddress}
        />
      )}
    </div>
  );
};

export default SelectAddres;
