import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import validations from "components/LabCreateMaster/schemas/validations";
import form from "components/LabCreateMaster/schemas/form";
import initialValues from "components/LabCreateMaster/schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";

import axios from "../../../axiosinstance";
import { APIs } from "Services/APIs";

import { ROLE_CODE } from "privateComponents/codes";
import LabCreateInfo from "components/LabCreateMaster/labCreateInfo";
import { labappCode } from "static/labappCode";

function getSteps() {
  return ["LabCreateInfo"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <LabCreateInfo formData={formData} />;
    default:
      return null;
  }
}

export default function LabListCreate(props) {
  const { openpopupMMUListCreate, setopenpopupMMUListCreate, passData } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.create);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getMMUDetails, getProjectDetails } = useSelector((state) => state);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const submitForm = async (values, actions) => {
    if (!values.latitude || !values.longitude) {
      swal("", "Save your location via add location first!", "warning");
      return;
    }

    setIsLoading(true);

    let pincodeobj = {
      ...values.pincodeobj,
      pincode: values.pincodeobj?.village?.name?.match(/\{(.*?)\}/)?.[1] || "",
    };

    const requestData = {
      address: {
        classname: "Address",
        firstline: values.firstline,
        secondline: values.secondline,
        postcode: pincodeobj?.pincode,
        country: values.country,
        state: values.pincodeobj?.state?.name,
        district: values.pincodeobj?.district?.name,
        subdistrict: values.pincodeobj?.subdistrict?.name,
        city: values.pincodeobj?.village?.name?.split(" {")[0],
        pincode: pincodeobj,
        location: {
          coordinates: [values.latitude, values.longitude],
          type: "string",
          x: values.latitude,
          y: values.longitude,
        },
        mmucode:
          auth?.rolecode === ROLE_CODE?.labcode
            ? getMMUDetails?.data?.mmucode
            : "",
        recstatus: "PRIMARY",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      },
      location: {
        coordinates: [values?.latitude, values?.longitude],
        type: "string",
        x: values.latitude,
        y: values.longitude,
      },
      createdby: auth?.profileid,
      createdbyname: auth?.name,
      type: labappCode.mappingcode ,
      description: values.description,
      name: values.name,
      mappingcode: getProjectDetails?.details?.projecthirarichalcode || "",
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      vehicle: values.VehicleAssign?values.VehicleAssign:{},
       raspberry: values.RaspberryAssign?values.RaspberryAssign:{},
       warehouse: values.mapware?values.mapware:{},
       equipmentlist: values.equimentslist,
    };

    const response = await axios.post(
      `${APIs?.mmuCreationService}/create`,
      requestData
    );
    if (!response.error) {
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });
      actions.resetForm();
      setIsLoading(false);
      passData();
      handleClose();
    } else {
      actions.resetForm();
      swal({
        icon: "error",
        title: "Error",
        text: error?.message,
      });
      setIsLoading(false);
      handleClose();
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setopenpopupMMUListCreate(false);
  };

  return (
    <Dialog
      open={openpopupMMUListCreate}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 6 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-2}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Create Lab
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={5}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={8}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
