

import checkout from "./form";
const {
  formField:{ vaccineName,fromAge,toAge,dependency,nature,applicable,brand,dosegaplist},
} = checkout;

const initialValues = {
  [vaccineName.name]: "",
  [fromAge.name]: "",
  [toAge.name]: "",
  [dependency.name]: [],
  [nature.name]: "",
  [applicable.name]: "",
  [brand.name]: [],
  [dosegaplist.name]: "",
};

export default initialValues;
