import React, { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { Card } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import swal from "sweetalert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import PriceInfo from "./PriceInfo";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";
import { useSelector } from "react-redux";

const ClinicPrice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableDatas, setTableDatas] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [priceList, setPriceList] = useState([]);
  const [pricePopup, setPricePopup] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const {getMMUDetails , getProjectDetails} = useSelector(state => state)
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );

  const iconStyleedit = {
    fontSize: "1.5em",
    color: "#344767",
    marginRight: "9px",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = {
    fontSize: "1.5em",
    color: "#344767",
    marginRight: "9px",
    cursor: "pointer",
    color: "red",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        axios
          .put(`${APIs.priceServiceApi}/delete`, item)
          .then((response) => {
            console.log(response);
            getApiResponse();
          });
        swal("Your price record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your price record is safe!");
      }
    });
  }
  function handleAddButton() {

    if(ROLE_CODE?.cliniccode === auth?.rolecode && getProjectDetails.details.mmucreation === null){
      swal("You can not perform this action, because you are not assign with lab", {
        icon: 'error',
      })
      return;
    }

    setPricePopup(true);
    setSelectedItem(null);
  }

  function getApiResponse() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "description" },
        { Header: "Vaccine name", accessor: "pricename" },
        { Header: "type", accessor: "type" },
        { Header: "Price type", accessor: "valuetype" },
        { Header: "currency", accessor: "currency" },
        { Header: "Value", accessor: "value" },
        { Header: "uom", accessor: "uom" },
        { Header: "Tax Preference", accessor: "taxinclusiveflag" },

        { Header: "Action", accessor: "action" },
      ],
      rows: [],
    };
    const api = ROLE_CODE?.cliniccode === auth?.rolecode  ? `${APIs.priceServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}`:`${APIs.priceServiceApi}/get?profileid=${profileId}`;
    axios
      .get(api)
      .then((response) => {
        setPriceList(response?.data?.data?.dtoList)
        response?.data?.data?.dtoList.map((item) => {
          item.action = (
            <span>
              <FaRegEdit
                style={iconStyleedit}
                onClick={() => {
                  setSelectedItem(item);
                  setPricePopup(true);
                }}
              />
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  onDelete(item);
                }}
              />
            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getApiResponse();
  }, []);

  return (
    <>
      <MDBox pt={3}>
        <Card>
          <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
            <div style={addButtonStyle}>
              <span
                style={{
                  alignSelf: "center",
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  Price List
                </MDTypography>
              </span>
              <span
                style={{
                  alignSelf: "start",
                  color: "#344767",
                }}
              >
                <MDButton color="info" onClick={handleAddButton}>
                  ADD
                </MDButton>
              </span>
            </div>
          </MDBox>
          <BarLoader
            color="#344767"
            loading={loading}
            aria-label="Loading Spinner"
          />
          {!loading && <DataTable table={tableDatas} canSearch={true} />}
        </Card>
      </MDBox>

      {pricePopup && (
        <PriceInfo
          pricePopup={pricePopup}
          setPricePopup={setPricePopup}
          data={selectedItem}
          getApiResponse={getApiResponse}
          priceList={priceList}
        />
      )}
    </>
  );
};

export default ClinicPrice;
