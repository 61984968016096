import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import MDInput from "components/MDInput";
import { APIs } from "Services/APIs";
import FormField from "./FormField/index";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import axios from "../../../../axiosinstance";
import { ErrorMessage, useFormikContext } from "formik";
import DataTable from "examples/Tables/DataTable";

import MDButton from "components/MDButton";
import AddIcon from "@mui/icons-material/Add";
import { FaRegTimesCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function EditVaccinemaster({ formData }) {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [loading, setLoading] = useState(false);
  const { setFieldValue, validateField } = useFormikContext();
  const [color] = useState("#344767");
  const { formField, values, errors, touched } = formData;
  const { vaccineName, fromAge, toAge, nature, dependency, applicable, brand } =
    formField;
  var {
    vaccineName: vaccineNameV,
    fromAge: fromAgeV,
    toAge: toAgeV,
    nature: natureV,
  } = values;
  const [dependencyValue, setDependencyValue] = React.useState(
    values.dependency
  );
  const [natureValue, setNatureValue] = React.useState(null);
  const [brandValue, setBrandValue] = React.useState([]);
  const [applicableValue, setApplicableValue] = React.useState([]);
  const [dependencyList, setDependencyList] = React.useState([]);
  const [brandList, setBrandList] = React.useState([]);
  const [list, setList] = useState(values.dosegaplist);
  const [noofdoses, setNoofdoses] = useState("");
  const [dosesgap, Setdosesgap] = useState("");

  const defaultPropsDependency = {
    options: dependencyList.length <= 0 ? [] : dependencyList,
    getOptionLabel: (option) => option?.name ? option?.name : "",
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };
  const defaultPropsNature = {
    options: [
      { name: "Mandatory", code: "Mandatory" },
      { name: "Optional", code: "Optional" },
      { name: "Recommended", code: "Recommended" },
    ],
    getOptionLabel: (option) =>  option?.name ? option?.name : "",
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };
  const defaultPropsApplicable = {
    options: [
      { name: "Male", code: "Male" },
      { name: "Female", code: "Female" },
      { name: "Other", code: "Other" },
    ],
    getOptionLabel: (option) =>  option?.name ? option?.name : "",
    isOptionEqualToValue: (option, value) => option?.code === value?.code,
  };

  const defaultPropsBrand = {
    options: brandList.length <= 0 ? [] : brandList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value?.code,
  };
  async function getVaccineData() {
    await axios
      .get(`${APIs.vaccineMasterServiceApi}/get`)
      .then((response) => {
        setDependencyList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getBrandData() {
    await axios
      .get(`${APIs.catgoryServiceApi}/get?type=BRAND`)
      .then((response) => {
        setBrandList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getVaccineData();
    getBrandData();
  }, []);
  useEffect(() => {
    const naturedata = defaultPropsNature.options.find(
      (i) => i.code === values.nature
    );
    setNatureValue(naturedata);

    const applicabledata = defaultPropsApplicable.options.find(
      (i) => i.code === values.applicable[0]
    );

    setApplicableValue(applicabledata);
    const branddata = brandList.filter((i) => values.brand.includes(i.name));

    setBrandValue(branddata);
  }, [brandList]);
  const handleDelete = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
    values.dosegaplist = newList;
  };

  const handleAdd = () => {
    if (noofdoses && dosesgap) {
      const newEntry = {
        dose_no: noofdoses,
        gap: dosesgap,
        recstatus: "APPROVED",
        status: "ACTIVE",
      };

      setList([...list, newEntry]);

      setNoofdoses("");
      Setdosesgap("");

      // Update the form values with the new list
      values.dosegaplist = [...list, newEntry];
    } else {
      swal({
        icon: "warning",
        title: "Incomplete Data",
        text: "Please fill out all fields before adding.",
      });
    }
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Edit Vaccine</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={vaccineName.type}
                label=<span>
                  {vaccineName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={vaccineName.name}
                value={vaccineNameV}
                placeholder={vaccineName.placeholder}
                error={errors.vaccineName && touched.vaccineName}
                success={vaccineNameV.length > 0 && !errors.vaccineName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDependency}
                value={dependencyValue}
                onChange={(event, newValue) => {
                  setDependencyValue(newValue);
                  values.dependency = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type={dependency.type}
                    error={errors.dependency && touched.dependency}
                    // success={dependencyV.length > 0 && !errors.dependency}
                    variant="standard"
                    label={dependency.label}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <FormField
                type={fromAge.type}
                label=<span>
                  {fromAge.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={fromAge.name}
                value={fromAgeV}
                placeholder={fromAge.placeholder}
                error={errors.fromAge && touched.fromAge}
                success={fromAgeV.length > 0 && !errors.fromAge}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type={toAge.type}
                label=<span>
                  {toAge.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={toAge.name}
                value={toAgeV}
                placeholder={toAge.placeholder}
                error={errors.toAge && touched.toAge}
                success={toAgeV.length > 0 && !errors.toAge}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsNature}
                value={natureValue}
                onChange={(event, newValue) => {
                  setNatureValue(newValue);
                  values.nature = newValue?.code;
                  validateField("nature");
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type={nature.type}
                    error={errors.nature && touched.nature}
                    // success={dependencyV.length > 0 && !errors.dependency}
                    variant="standard"
                    label=<span>
                      {nature.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                  />
                )}
              />
              <ErrorMessage
                name="nature"
                component="div"
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsApplicable}
                value={applicableValue}
                onChange={(event, newValue) => {
                  setApplicableValue(newValue);
                  values.applicable = newValue?.code;
                  validateField("applicable");
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type={applicable.type}
                    error={errors.applicable && touched.applicable}
                    variant="standard"
                    label=<span>
                      {applicable.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                  />
                )}
              />
              <ErrorMessage
                name="applicable"
                component="div"
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                value={brandValue}
                {...defaultPropsBrand}
                disableCloseOnSelect
                onChange={(event, newValue) => {
                  setBrandValue(newValue);
                  values.brand = newValue.map((item) => item.name); // Extract only names

                  validateField("brand");
                }}
                renderOption={(props, option, { selected }) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type={brand.type}
                    error={errors.brand && touched.brand}
                    // success={
                    //   brandValue != null &&
                    //   !errors.brand &&
                    //   touched.brand
                    // }
                    variant="standard"
                    label=<span>
                      {brand.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                  />
                )}
              />
              <ErrorMessage
                name="brand"
                component="div"
                style={{
                  color: "red",
                  fontSize: "12px",
                  textAlign: "left",
                  marginTop: "4px",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={0.5}>
            <Grid item xs={12} sm={3}>
              <MDInput
                label="Dose no"
                variant="standard"
                type="number"
                value={noofdoses}
                onChange={(e) => setNoofdoses(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <MDInput
                label="Doses Gap"
                variant="standard"
                type="number"
                value={dosesgap}
                onChange={(e) => Setdosesgap(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2} mt={2}>
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={handleAdd}
                startIcon={<AddIcon />}
              >
                Add
              </MDButton>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "10px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <DataTable
                imageMaxWidth={"100px"}
                canSearch={false}
                showTotalEntries={false}
                pagination={false}
                entriesPerPage={false}
                table={{
                  columns: [
                    { Header: "Dose no", accessor: "dose_no" },
                    {
                      Header: "Enter the Gap Between Doses(In Days)",
                      accessor: "gap",
                    },

                    {
                      Header: "Actions",
                      accessor: "actions",
                      Cell: ({ row }) => (
                        <span>
                          <FaRegTimesCircle
                            style={{
                              color: "#f44336",
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDelete(row.index)}
                          />
                        </span>
                      ),
                      width: "15%",
                    },
                  ],
                  rows: list?.map((item, index) => ({
                    ...item,
                  })),
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}
EditVaccinemaster.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default EditVaccinemaster;
